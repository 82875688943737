import { useApolloClient } from '@apollo/react-hooks'
import type { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'
import classnames from 'classnames'
import { useSnackbar } from 'notistack'
import React, { useState } from 'react'
import Button from '../Button'
import Dropzone from '../Dropzone'
import FilePreview from '../FilePreview'
import InputLabel from '../InputLabel'
import Spinner from '../Spinner'
import type { StyleClasses } from '../styles'

import { getFeature } from '@paintscout/util/builder'
import type { UploadOptions } from '@paintscout/util/s3'
import { PERMITTED_FILE_TYPES, compressFile, uploadFile } from '@paintscout/util/s3'
import { useClientOptions } from '../ClientOptionsProvider'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start'
  },
  label: {
    marginBottom: theme.typography.pxToRem(5)
  },
  dropzone: {
    position: 'relative',
    flexGrow: 1,
    '& $button': {
      // material-ui will spit out a warning about not being able to find $button, but it still works
      // https://github.com/mui-org/material-ui/issues/15511
      minHeight: (props: Partial<UploadImageProps>) => (props.buttonHeight ? props.buttonHeight : 0)
    }
  },
  button: {
    height: '100%'
  },
  above: {
    display: 'flex',
    alignItems: 'space-between'
  },
  clearButton: {
    marginTop: theme.spacing()
  },
  clearWrapper: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%'
  },
  acceptDrop: {},
  rejectDrop: {
    '& $button': {
      borderColor: theme.palette.grey.A100
    }
  },
  previewOverlay: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    background: 'rgba(255,255,255,0.7)'
  },
  preview: {
    width: '100%',
    height: '100%'
  },
  img: {
    '&:not($disabled)': {
      cursor: 'pointer'
    },
    width: '100%',
    height: '100%',
    objectFit: 'contain'
  },
  disabled: {},
  filePreview: {
    border: `1px dashed transparent`,
    '&:hover': {
      cursor: (props: Partial<UploadImageProps>) => (!props.disabled ? 'pointer' : 'default')
    }
  },
  activeOutline: {
    borderColor: `${theme.palette.primary.main}`
  },
  rejectOutline: {
    borderColor: `${theme.palette.error.main}`
  }
}))

export interface UploadImageProps {
  classes?: StyleClasses<typeof useStyles>
  uploadOptions?: UploadOptions
  /**
   * use clodinaryPublicId or s3PublicKey if possible,
   * but need to pass to make src for resizable image(s3 images)
   */
  src: string
  // Desired image sources
  cloudinaryPublicId?: string
  s3PublicKey?: string
  label?: string
  className?: string
  disabled?: boolean
  disabledNote?: string
  disableClear?: boolean
  format?: string
  pages?: number
  accept?: string | string[]
  maxWidth?: number
  maxHeight?: number
  buttonHeight?: number
  quality?: number
  onUpload: (args: {
    src: string
    cloudinaryPublicId: string
    s3PublicKey: string
    format: string
    pages: number
    width: number
    height: number
    type: string
  }) => any
  onClear: () => any
}

function UploadImage({
  className,
  src,
  cloudinaryPublicId,
  s3PublicKey,
  format,
  pages,
  label,
  disabled,
  disabledNote,
  disableClear,
  onClear,
  onUpload,
  maxWidth,
  maxHeight,
  buttonHeight,
  accept = PERMITTED_FILE_TYPES,
  uploadOptions,
  ...props
}: UploadImageProps) {
  console.log({ src, cloudinaryPublicId, s3PublicKey })
  const { enqueueSnackbar } = useSnackbar()
  const classes = useStyles({ buttonHeight, ...props })
  const [loading, setLoading] = useState(false)
  const apolloClient = useApolloClient()
  const { options, clientId } = useClientOptions()
  const publicId = cloudinaryPublicId || s3PublicKey
  const s3UploadFeatureEnabled = getFeature({ options, path: 's3ImageUpload.enabled' })

  async function handleDrop(files: File[]) {
    try {
      setLoading(true)
      const file = await compressFile({ file: files[0], maxHeight, maxWidth })
      const { format, pages, width, height, type, src, cloudinaryPublicId, s3PublicKey } = await uploadFile({
        apolloClient,
        file,
        uploadOptions: {
          companyId: clientId,
          s3UploadFeatureEnabled,
          ...uploadOptions
        }
      })

      onUpload({ src, format, pages, cloudinaryPublicId, s3PublicKey, width, height, type })
    } catch (e) {
      console.error(e)
      enqueueSnackbar('Unable to upload image', { variant: 'error' })
    }
    setLoading(false)
  }

  return (
    <div
      {...props}
      className={classnames(
        {
          [classes.root]: true,
          [classes.disabled]: !!disabled
        },
        className
      )}
    >
      <div className={classes.above}>
        {label && (
          <InputLabel shrink={true} className={classes.label}>
            {label}
          </InputLabel>
        )}
      </div>
      <Dropzone
        className={classes.dropzone}
        onDrop={handleDrop}
        disabled={loading || disabled}
        text={disabledNote ?? ''}
        accept={accept}
        buttonFullHeight={true}
        shouldRenderPreview={loading || !!publicId}
        renderPreview={({ isDragActive, draggedFiles, isDragReject }) => {
          const rejectDrag = isDragReject && draggedFiles.filter((file) => file.type !== '').length > 0
          return (
            <div className={classes.preview}>
              {loading ? (
                <div className={classes.previewOverlay}>
                  <Spinner fullWidth={true} fullHeight={true} />
                </div>
              ) : (
                <FilePreview
                  className={classnames({
                    [classes.filePreview]: true,
                    [classes.activeOutline]: isDragActive && !rejectDrag,
                    [classes.rejectOutline]: rejectDrag
                  })}
                  file={{ src, s3PublicKey, cloudinaryPublicId, type: 'image', format, pages, visibility: 'visible' }}
                />
              )}
            </div>
          )
        }}
      />
      {!loading && !disabled && !disableClear && (
        <div className={classes.clearWrapper}>
          <Button className={classes.clearButton} variant="text" disabled={!src && !publicId} onClick={onClear}>
            Clear
          </Button>
        </div>
      )}
    </div>
  )
}

export default UploadImage
