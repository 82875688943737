import React from 'react'
import { makeStyles } from '@material-ui/core'
import type { Theme } from '@material-ui/core/styles'
import type { StyleClasses } from '@ui/core/theme'
import { Button, Typography } from '@ui/paintscout'
import { usePresentationNav } from '../../PresentationNavContext'
import classNames from 'classnames'
import type { PresentationOption } from 'paintscout'

const useStyles = makeStyles<Theme, BenjaminMooreNavProps>(
  (theme) => ({
    root: {
      marginTop: '10px',
      background: '#fff',
      marginRight: '5px'
    },
    titleWrapper: {
      padding: '4px 10px',
      display: 'flex',
      justifyContent: 'center',
      boxShadow: '0px 2px 8px -3px rgba(0, 0, 0, 0.44)'
    },
    container: {
      border: '1px solid',
      borderColor: theme.palette.grey[500]
    },
    button: {
      whiteSpace: 'normal',
      textAlign: 'left',
      justifyContent: 'flex-start',
      height: 'auto',
      width: '100%',
      padding: `${theme.typography.pxToRem(10)} 10px`,
      borderRadius: 0,
      borderBottom: `2px solid transparent`,
      marginTop: '0 !important'
    },
    buttonText: {
      whiteSpace: 'normal',
      fontWeight: 600,
      fontSize: '0.9rem'
    },
    buttonWrapper: {
      marginBottom: theme.spacing(1),
      '&:first-child button': {
        marginTop: 0
      },

      '&:last-child': {
        marginBottom: 0
      }
    },
    currentPage: {
      backgroundColor: theme.palette.grey[300]
    },
    currentSection: {
      backgroundColor: theme.palette.grey[300]
    }
  }),
  { name: 'BenjaminMooreNav' }
)

export interface BenjaminMooreNavProps {
  classes?: StyleClasses<typeof useStyles>
  presentation: PresentationOption
}

function BenjaminMooreNav(props: BenjaminMooreNavProps) {
  const classes = useStyles(props)
  const { presentation } = props
  const { onPageClick, currentPage } = usePresentationNav()

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <div className={classes.titleWrapper}>
          <h4>Benjamin Moore</h4>
        </div>

        {presentation?.advanced?.partnerContent?.benjaminMoore?.pages?.whyBenjaminMoore?.enabled && (
          <Button
            disableRipple={true}
            classes={{
              root: classNames({
                [classes.button]: true,
                [classes.currentPage]: currentPage === 'benjamin-moore-main',
                [classes.currentSection]: currentPage === 'benjamin-moore-main'
              })
            }}
            variant={'text'}
            // style={{ width: '100%' }}
            onClick={(ev) => {
              onPageClick(ev, 'benjamin-moore-main')
            }}
          >
            <Typography noWrap className={classes.buttonText}>
              Why Benjamin Moore
            </Typography>
          </Button>
        )}
      </div>
    </div>
  )
}

export default BenjaminMooreNav
