import React, { useRef } from 'react'
import { makeStyles } from '@material-ui/core'
import type { Theme } from '@material-ui/core/styles'
import type { StyleClasses } from '@ui/core/theme'
import classnames from 'classnames'
import type { QuoteFile } from 'paintscout'
import { generateSrcSet } from '@ui/core/util'

const useStyles = makeStyles<Theme, ResponsiveImageProps>(
  (_theme) => ({
    root: {
      color: 'blue',
      objectFit: 'contain'
    },
    image: {
      maxWidth: '100%',
      height: 'auto',
      maxHeight: '100%',
      objectFit: 'contain'
    }
  }),
  { name: 'ResponsiveImage' }
)

export interface ResponsiveImageProps {
  classes?: StyleClasses<typeof useStyles>
  file: QuoteFile
  size?: number
  page?: number
  onClick: (ev: any) => any
}

function ResponsiveImage(props: ResponsiveImageProps) {
  const classes = useStyles(props)
  const { file, onClick, size } = props
  const imageRef = useRef(null)

  // Need to organize or src url here for image specific sizing and type demands
  if (file.type.includes('svg')) {
    const generatedSrc = generateSrcSet({ file, svg: true })
    return (
      <>
        <img
          ref={imageRef}
          className={classnames(classes.fileRoot, classes.image, classes.root)}
          src={generatedSrc}
          onClick={onClick}
          width={'auto'}
        />
      </>
    )
  }

  if (size) {
    // Maybe not?
    const generatedSrc = generateSrcSet({ file, size })
    return (
      <>
        <img
          ref={imageRef}
          className={classnames(classes.fileRoot, classes.image)}
          src={generatedSrc}
          onClick={onClick}
        />
      </>
    )
  }

  const generatedSrc = generateSrcSet({ file })
  return (
    <>
      <img
        ref={imageRef}
        srcSet={generatedSrc}
        sizes="
            (max-width: 400px) 300px,
            (max-width: 600px) 500px,
            (max-width: 800px) 700px,
            (max-width: 1000px) 900px,
            1200px"
        className={classnames(classes.fileRoot, classes.image)}
        onClick={onClick}
      />
    </>
  )
}

export default ResponsiveImage
