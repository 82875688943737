import React from 'react'
import type { SvgIconProps } from '@material-ui/core/SvgIcon'
import SvgIcon from '@material-ui/core/SvgIcon'

const GPlusIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox={'0 0 250 250'} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M208.111 37H41.8889C39.1888 37 37 39.1888 37 41.8889V208.111C37 210.811 39.1888 213 41.8889 213H208.111C210.811 213 213 210.811 213 208.111V41.8889C213 39.1888 210.811 37 208.111 37ZM103.438 119.496V132.436H124.859C123.995 137.989 118.384 148.718 103.438 148.718C90.5422 148.718 80.0206 138.043 80.0206 124.888C80.0206 111.732 90.5422 101.057 103.438 101.057C110.776 101.057 115.686 104.184 118.492 106.88L128.744 97.0137C122.161 90.8674 113.636 87.1473 103.438 87.1473C82.5566 87.1473 65.6681 104.023 65.6681 124.888C65.6681 145.752 82.5566 162.628 103.438 162.628C125.236 162.628 139.697 147.316 139.697 125.75C139.697 123.27 139.427 121.383 139.103 119.496H103.438ZM173.566 119.686H184.332V130.452H173.566V141.217H162.801V130.452H152.035V119.686H162.801V108.92H173.566V119.686Z"
    />
  </SvgIcon>
)
export default GPlusIcon
