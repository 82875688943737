import React from 'react'
import type { SvgIconProps } from '@material-ui/core/SvgIcon'
import SvgIcon from '@material-ui/core/SvgIcon'

const TiktokIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox={'0 0 250 250'} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M138.353 42.8534H161.59C163.83 54.6436 171.008 64.7604 180.952 71.0812C180.955 71.0852 180.959 71.0893 180.962 71.0933C187.885 75.4917 196.138 78.0579 204.999 78.0579V84.9466L205 108.999C188.541 108.999 173.29 103.865 160.841 95.1535V158.041C160.841 189.449 134.634 215 102.42 215C89.972 215 78.4285 211.173 68.9399 204.677C68.9348 204.672 68.9297 204.666 68.9236 204.66C53.8672 194.347 44 177.299 44 158.035C44 126.629 70.2072 101.076 102.422 101.076C105.094 101.076 107.714 101.291 110.294 101.632V108.963L110.293 133.23C107.801 132.47 105.166 132.027 102.42 132.027C87.7082 132.027 75.7395 143.698 75.7395 158.041C75.7395 168.03 81.5518 176.708 90.0441 181.065C93.7468 182.965 97.9528 184.054 102.419 184.054C116.791 184.054 128.515 172.908 129.049 159.02L129.1 35H160.84C160.84 37.6826 161.106 40.3044 161.59 42.8534H138.353Z"
    />
  </SvgIcon>
)
export default TiktokIcon
