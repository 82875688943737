import type { Theme } from '@material-ui/core'
import { Grid, makeStyles, Typography } from '@material-ui/core'
import { AlertDialog, Collapse, LearnMoreLink, useClientOptions, useDialogs, useUser } from '@ui/paintscout'
import React from 'react'
import { useQuote } from '../../context'
import type { LoanApplicationStatus } from '../types'
import { useWisetack } from '../useWisetack'
import { useConnection } from 'react-detect-offline'
import WisetackLearnMore from '../WisetackLearnMore'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    '@media print': {
      filter: 'grayscale(1)'
    },
    padding: theme.spacing(2),
    paddingTop: 0
  },
  logo: {
    width: theme.typography.pxToRem(120),
    marginRight: theme.spacing(1)
  },
  dialogLogo: {
    width: theme.typography.pxToRem(120),
    objectFit: 'contain',
    margin: theme.spacing(1, 0)
  }
}))

export interface WisetackPromoProps {
  inPresentation?: boolean
  inCustomerView?: boolean
}

export default function WisetackPromo({ inPresentation, inCustomerView }: WisetackPromoProps) {
  const classes = useStyles()
  const { options } = useClientOptions()
  const { user, preferences } = useUser()
  const { online } = useConnection()
  const { wisetackPromo, quote } = useQuote()
  const { hasWisetack, merchantSignupDate, showOnOldDocuments, handleGetLoanApplication } = useWisetack({
    options,
    user,
    preferences
  })
  const contact = quote?.contact
  const companyContact = !!quote?.contact?.company

  const { openDialog, dismissDialog } = useDialogs()

  const loanStatus = contact?.wisetackLoanApplication?.changedStatus as LoanApplicationStatus | undefined

  const hideBasedOnSignup =
    (inPresentation && !hasWisetack) ||
    (merchantSignupDate && merchantSignupDate.isAfter(quote?.dates?.created) && !showOnOldDocuments)
  const hideBasedOnLoanApp = inPresentation && loanStatus === 'DECLINED'

  const hide = hideBasedOnSignup || hideBasedOnLoanApp || (inPresentation && !contact) || !online || companyContact

  const handleLearnMore = () => {
    openDialog(
      AlertDialog,
      {
        title: '',
        message: (
          <WisetackLearnMore
            handleGetLoanApplication={() => handleGetLoanApplication(quote)}
            wisetackPromo={wisetackPromo}
            isCustomerView={inCustomerView}
          />
        ),
        onConfirm: dismissDialog,
        icon: (
          <img
            className={classes.dialogLogo}
            src={
              'https://res.cloudinary.com/taptapquote/image/upload/v1663601481/paintscout/providers/wisetack-alternate.png'
            }
            alt={'Wisetack'}
          />
        )
      },
      {
        replace: true
      }
    )
  }
  return (
    <Collapse show={!!wisetackPromo && !wisetackPromo.error && !hide}>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={1}
        className={classes.root}
      >
        <Grid item>
          <Typography align="center">
            Or as low as <strong>${wisetackPromo?.asLowAs?.monthlyPayment}/month*</strong> with{' '}
          </Typography>
        </Grid>
        <Grid container item alignItems="flex-end" justifyContent="center">
          <img
            className={classes.logo}
            alt="Wisetack"
            src={
              'https://res.cloudinary.com/taptapquote/image/upload/v1663601481/paintscout/providers/wisetack-alternate.png'
            }
          />
          <LearnMoreLink id="allow-propagation" onClick={handleLearnMore} />
        </Grid>
      </Grid>
    </Collapse>
  )
}
