import type { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'
import { Button, Typography, useClientOptions } from '@ui/paintscout'
import React from 'react'
import { WisetackDisclaimerContent } from '../WisetackPromoDisclaimer'
import { getObjectLabels } from '@paintscout/util/builder'
import type { WisetackPromoResponse } from '../types'

const useStyles = makeStyles((theme: Theme) => ({
  textWrapper: {
    textAlign: 'left'
  },
  textSpacing: {
    marginBottom: theme.spacing(2)
  },
  disclaimer: {
    fontStyle: 'italic',
    textAlign: 'center'
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: theme.spacing(2)
  }
}))

export interface WisetackLearnMoreProps {
  handleGetLoanApplication: () => Promise<void>
  isCustomerView?: boolean
  wisetackPromo?: WisetackPromoResponse
}

export default function WisetackLearnMore({
  handleGetLoanApplication,
  isCustomerView,
  wisetackPromo
}: WisetackLearnMoreProps) {
  const classes = useStyles()
  const content = isCustomerView ? (
    <CustomerViewContent handleGetLoanApplication={handleGetLoanApplication} wisetackPromo={wisetackPromo} />
  ) : (
    <MerchantContent />
  )
  return (
    <>
      {content}
      <div className={classes.disclaimer}>
        <WisetackDisclaimerContent wisetackPromo={wisetackPromo} />
      </div>
    </>
  )
}

const MerchantContent = () => {
  const classes = useStyles()
  const { options } = useClientOptions()
  const { quote: quoteLabel } = getObjectLabels({ options, invoice: false })
  return (
    <div className={classes.textWrapper}>
      <Typography variant="h6" className={classes.textSpacing}>
        How-to
      </Typography>
      <Typography className={classes.textSpacing}>
        Financing Application Process in 3 Easy Steps:
        <ol>
          <li>
            Send a financing application (or a qualifying {quoteLabel.value.toLowerCase()}) to your customer (no fee to
            you or impact to their credit score)
          </li>
          <li>
            Once your customer is approved and they’ve locked in an offer, complete the job and get paid from Wisetack!
          </li>
        </ol>
      </Typography>
      <Typography variant="h6" className={classes.textSpacing}>
        Steps for your Customer
      </Typography>
      <Typography className={classes.textSpacing}>
        Apply in less than 60 seconds and get approved for up to $25,000, with no credit score impact! Select the
        monthly payment that works best within the budget. Accept the terms and mark the work as complete.
      </Typography>
    </div>
  )
}

const CustomerViewContent = ({
  handleGetLoanApplication,
  wisetackPromo
}: {
  handleGetLoanApplication: () => Promise<void>
  wisetackPromo: WisetackPromoResponse
}) => {
  const classes = useStyles()
  return (
    <div className={classes.textWrapper}>
      <Typography variant="h6" className={classes.textSpacing}>
        {wisetackPromo.headline}
      </Typography>
      <Typography className={classes.textSpacing}>
        {wisetackPromo.tagline}
        <ul>
          <li>{wisetackPromo.valueProp1}</li>
          <li>{wisetackPromo.valueProp2}</li>
          <li>{wisetackPromo.valueProp3}</li>
        </ul>
      </Typography>
      <div className={classes.buttonWrapper}>
        <Button onClick={handleGetLoanApplication}>{wisetackPromo.button}</Button>
      </div>
    </div>
  )
}
