import type { PresentationIsEmptyProps } from '../..'
export default function isEmpty({ section }: PresentationIsEmptyProps) {
  const fields = section?.fields
  if (!fields) {
    return true
  }

  if (!fields.leftImage && !fields.rightImage) {
    return true
  }

  return false
}
