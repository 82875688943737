import React from 'react'
import type {
  PresentationSectionFormProps,
  FormikPresentationSectionConnectProps,
  FormikPresentationSectionValues
} from '../..'
import { connect, Field } from 'formik'

import { Grid, Typography, FormikInputField, FormikEmbedMedia } from '@ui/paintscout'

const SliderImageForm = (_props: PresentationSectionFormProps & FormikPresentationSectionConnectProps) => {
  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography>
            This section will have two images with a slider to show or hide them. Both images need to be the same size
            to display correctly.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Field component={FormikInputField} label={'Title'} name={'section.title'} fullWidth={true} />
        </Grid>
        <Grid item xs={6} sm={6} md={6}>
          <Field
            name="section.fields.leftImage"
            component={FormikEmbedMedia}
            hideYoutube
            maxWidth={2000}
            maxHeight={1600}
            buttonHeight={100}
            quality={1}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={6}>
          <Field
            name="section.fields.rightImage"
            component={FormikEmbedMedia}
            hideYoutube
            maxWidth={2000}
            maxHeight={1600}
            buttonHeight={100}
            quality={1}
          />
        </Grid>
      </Grid>
    </div>
  )
}

export default connect<PresentationSectionFormProps, FormikPresentationSectionValues>(SliderImageForm)
