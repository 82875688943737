import { useApolloClient } from '@apollo/react-hooks'
import { PERMITTED_FILE_TYPES, compressFile, uploadFile } from '@paintscout/util/s3'
import { useCallback, useState } from 'react'
import { useDropzone } from 'react-dropzone'

import { getFeature } from '@paintscout/util/builder'
import { useSnackbar } from 'notistack'
import { useClientOptions } from '../../../ClientOptionsProvider'
import type { FileDropAreaProps } from '../../FileDropArea'
import { useUser } from '../../../UserProvider'

export default function useFileDropArea({
  onUpload,
  onUploadStart,
  onPartialUpload,
  onPartialUploadError,
  docId,
  ...otherProps
}: Omit<FileDropAreaProps, 'children'>) {
  const apolloClient = useApolloClient()
  const { enqueueSnackbar } = useSnackbar()
  const { user } = useUser()
  const { options } = useClientOptions()
  const s3UploadFeatureEnabled = getFeature({ options, path: 's3ImageUpload.enabled' })
  const [loading, setLoading] = useState(false)
  const onDrop = useCallback(async (uploadFiles: File[]) => {
    if (uploadFiles.find((file) => !PERMITTED_FILE_TYPES.includes(file.type))) {
      enqueueSnackbar('Unable to upload file type.', { variant: 'error' })
      return false
    }
    if (onUploadStart) {
      onUploadStart()
    }
    setLoading(true)

    const { maxHeight, maxWidth } = otherProps

    const allUploadRes = []
    for (let i = 0; i < uploadFiles.length; i++) {
      try {
        const file = uploadFiles[i]
        const compressedFile = await compressFile({
          file,
          maxHeight,
          maxWidth
        })

        const uploadedFile = await uploadFile({
          file: compressedFile,
          apolloClient,
          uploadOptions: {
            companyId: user?.app_metadata?.companyId,
            docId,
            s3UploadFeatureEnabled
          }
        })

        if (onPartialUpload) {
          onPartialUpload({ totalFiles: uploadFiles.length, index: i })
        }

        allUploadRes.push(uploadedFile)
      } catch (err) {
        if (onPartialUploadError) {
          onPartialUploadError({ totalFiles: uploadFiles.length, index: i })
        }
        allUploadRes.push(err)
      }
    }

    setLoading(false)

    onUpload(allUploadRes)
  }, [])

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop,
    noClick: true,
    noKeyboard: true
  })

  return { getRootProps, getInputProps, isDragActive, open, loading }
}
