import type { PresentationIsEmptyProps } from '../../'

export default function isEmpty({ section }: PresentationIsEmptyProps) {
  const { fields } = section

  if (!fields) {
    return true
  }

  if (!fields || !fields.rows || fields.rows.length === 0) {
    return true
  }

  return false
}
