import React from 'react'
import type {
  PresentationSectionFormProps,
  FormikPresentationSectionConnectProps,
  FormikPresentationSectionValues
} from '../../'
import { connect, Field, FieldArray } from 'formik'
import { Button, Grid, FieldGroup, FormikInputField } from '@ui/paintscout'
import { MoveRemoveButtons } from '../../util'
import YouTube from '@material-ui/icons/YouTube'
import Facebook from '@material-ui/icons/Facebook'
import PinterestIcon from '@material-ui/icons/Pinterest'
import Instagram from '@material-ui/icons/Instagram'
import WhatsAppIcon from '@material-ui/icons/WhatsApp'
import PhoneIcon from '@material-ui/icons/Phone'
import EmailIcon from '@material-ui/icons/Email'
import LinkedInIcon from '@material-ui/icons/LinkedIn'
import { Popover, Typography } from '@material-ui/core'
import { GPlusIcon, HomestarsIcon, HouzzIcon, TiktokIcon, XIcon, YelpIcon } from '@ui/core/icons'
import { makeStyles } from '@material-ui/core'
import type { Theme } from '@material-ui/core/styles'

export const socialIcons = {
  instagram: { icon: Instagram, name: 'Instagram' },
  facebook: { icon: Facebook, name: 'Facebook' },
  tiktok: { icon: TiktokIcon, name: 'Tiktok' },
  youtube: { icon: YouTube, name: 'YouTube' },
  linkedin: { icon: LinkedInIcon, name: 'LinkedIn' },
  x: { icon: XIcon, name: 'X' },
  pinterest: { icon: PinterestIcon, name: 'Pinterest' },
  yelp: { icon: YelpIcon, name: 'Yelp' },
  houzz: { icon: HouzzIcon, name: 'Houzz' },
  homestars: { icon: HomestarsIcon, name: 'Homestars' },
  google: { icon: GPlusIcon, name: 'Google+' },
  whatsapp: { icon: WhatsAppIcon, name: 'Whatsapp' },
  phone: { icon: PhoneIcon, name: 'Phone', prefix: 'tel:' },
  email: { icon: EmailIcon, name: 'Email', prefix: 'mailto:' }
}

const useStyles = makeStyles<Theme>(
  (_theme) => ({
    root: {},
    socialIcons: { fontSize: '25px', color: 'inherit' },
    button: { justifyContent: 'flex-start' }
  }),
  { name: 'PresentationSectionForm' }
)

const SocialsForm = ({ formik }: PresentationSectionFormProps & FormikPresentationSectionConnectProps) => {
  const classes = useStyles({})
  const { section } = formik.values
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  return (
    <Grid container spacing={3}>
      <Grid item md={12}>
        <Field component={FormikInputField} fullWidth={true} name={'section.title'} label={'Title'} />
      </Grid>

      <Grid item md={12}>
        <FieldGroup label={'Social Media'}>
          <FieldArray
            name="section.fields.rows"
            render={(arrayHelpers) => (
              <Grid container spacing={3}>
                {(section?.fields?.rows ?? []).map((row, index) => (
                  <Grid key={index} item xs={12}>
                    <SocialsFormItem section={section} arrayHelpers={arrayHelpers} index={index} />
                  </Grid>
                ))}
                {(section?.fields?.rows ?? []).length < 12 && (
                  <Grid item xs={12}>
                    <Button
                      aria-describedby={id}
                      onClick={(event) => {
                        setAnchorEl(event.currentTarget)
                      }}
                      variant={'outlined'}
                      fullWidth={true}
                    >
                      Add Link
                    </Button>
                    <Popover
                      anchorEl={anchorEl}
                      open={open}
                      onClose={() => setAnchorEl(null)}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center'
                      }}
                      transformOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center'
                      }}
                    >
                      <Grid container direction="column" justifyContent="flex-start" spacing={0}>
                        {Object.keys(socialIcons).map((key) => {
                          const icon = socialIcons[key]
                          const SocialIcon = icon.icon
                          return (
                            <Button
                              className={classes.button}
                              key={key}
                              onClick={() => {
                                arrayHelpers.push({
                                  icon: key,
                                  link: '',
                                  ...(icon?.prefix ? { prefix: icon.prefix } : {})
                                })
                                setAnchorEl(null)
                              }}
                              variant={'text'}
                              fullWidth
                            >
                              <Grid container direction="row" alignItems="center" spacing={3}>
                                <Grid item xs={3}>
                                  <SocialIcon className={classes.socialIcon} />
                                </Grid>
                                <Grid item xs={8}>
                                  <Typography variant={'h6'}>{socialIcons[key].name}</Typography>
                                </Grid>
                              </Grid>
                            </Button>
                          )
                        })}
                      </Grid>
                    </Popover>
                  </Grid>
                )}
              </Grid>
            )}
          />
        </FieldGroup>
      </Grid>
    </Grid>
  )
}

function SocialsFormItem({ index, section, arrayHelpers }) {
  const { icon } = section.fields.rows[index]
  const SocialIcon = socialIcons[icon].icon
  return (
    <Grid container justifyContent="center" alignItems="center" spacing={0}>
      <Grid item xs={2}>
        <SocialIcon style={{ fontSize: '50px', color: section?.background?.textColor ?? 'inherit' }} />
      </Grid>
      <Grid item md={9}>
        <Grid item md={12}>
          <Field
            component={FormikInputField}
            label={'Link'}
            name={`section.fields.rows.${index}.link`}
            fullWidth={true}
          />
        </Grid>
      </Grid>
      <MoveRemoveButtons arrayHelpers={arrayHelpers} index={index} section={section} />
    </Grid>
  )
}
export default connect<PresentationSectionFormProps, FormikPresentationSectionValues>(SocialsForm)
