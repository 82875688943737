import React from 'react'
import type { SvgIconProps } from '@material-ui/core/SvgIcon'
import SvgIcon from '@material-ui/core/SvgIcon'

const YelpIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox={'0 0 250 250'} {...props}>
    <g clipPath="url(#clip0_110_45)">
      <path d="M100.121 148.363L108.411 146.427C108.586 146.387 108.874 146.32 109.225 146.205C111.513 145.583 113.473 144.088 114.693 142.034C115.913 139.979 116.298 137.527 115.767 135.193C115.755 135.142 115.744 135.093 115.732 135.042C115.46 133.924 114.983 132.867 114.326 131.926C113.397 130.735 112.247 129.738 110.941 128.992C109.403 128.105 107.794 127.352 106.131 126.74L97.0411 123.386C91.9395 121.469 86.8389 119.6 81.6899 117.778C78.351 116.577 75.5217 115.523 73.0634 114.754C72.5997 114.61 72.0875 114.465 71.6717 114.32C68.6997 113.4 66.6113 113.018 64.8445 113.006C63.6643 112.962 62.4887 113.178 61.3992 113.64C60.2638 114.135 59.244 114.867 58.4068 115.787C57.9904 116.266 57.5991 116.766 57.2337 117.285C56.8912 117.815 56.5773 118.363 56.293 118.926C55.9796 119.531 55.7105 120.158 55.4878 120.802C53.8005 125.842 52.9602 131.133 53.0014 136.454C53.0315 141.264 53.1594 147.438 55.7752 151.623C56.4056 152.697 57.2515 153.625 58.2585 154.348C60.1216 155.649 62.0051 155.82 63.964 155.962C66.8916 156.175 69.7281 155.448 72.5553 154.788L100.098 148.356L100.121 148.363Z" />
      <path d="M192.894 104.575C190.643 99.8362 187.617 95.5105 183.941 91.7765C183.466 91.3028 182.957 90.8641 182.417 90.4646C181.92 90.09 181.402 89.7418 180.868 89.4211C180.319 89.1191 179.754 88.8467 179.176 88.6053C178.032 88.1549 176.807 87.9521 175.58 88.0095C174.406 88.0766 173.262 88.3989 172.224 88.9545C170.652 89.7388 168.951 90.9983 166.688 93.1083C166.376 93.4199 165.983 93.7711 165.63 94.1025C163.763 95.8633 161.687 98.0337 159.215 100.561C155.397 104.431 151.638 108.322 147.901 112.255L141.215 119.21C139.991 120.48 138.876 121.853 137.883 123.313C137.037 124.546 136.438 125.933 136.12 127.397C135.937 128.519 135.963 129.666 136.2 130.778C136.212 130.828 136.222 130.876 136.234 130.926C136.762 133.23 138.16 135.237 140.134 136.525C142.108 137.814 144.503 138.283 146.816 137.834C147.182 137.78 147.466 137.717 147.639 137.675L183.285 129.414C186.094 128.763 188.945 128.166 191.469 126.696C193.161 125.712 194.771 124.736 195.876 122.767C196.466 121.685 196.823 120.491 196.925 119.262C197.471 114.39 194.935 108.856 192.894 104.575Z" />
      <path d="M128.356 119.531C130.946 116.273 130.939 111.416 131.171 107.445C131.95 94.179 132.771 80.9112 133.422 67.6378C133.671 62.6102 134.211 57.6507 133.912 52.5849C133.666 48.4058 133.636 43.6076 131.002 40.178C126.352 34.1302 116.436 34.6273 109.671 35.5654C107.598 35.8535 105.522 36.2409 103.462 36.7361C101.403 37.2313 99.3646 37.7722 97.3706 38.4119C90.8785 40.5418 81.7522 44.4514 80.2068 51.9436C79.3355 56.1792 81.4001 60.5119 83.0014 64.3773C84.9426 69.0629 87.5946 73.2837 90.0113 77.6944C96.4003 89.3389 102.908 100.911 109.398 112.495C111.336 115.953 113.448 120.332 117.198 122.124C117.446 122.232 117.699 122.329 117.956 122.412C119.637 123.049 121.47 123.172 123.222 122.764C123.327 122.74 123.431 122.716 123.534 122.692C125.152 122.251 126.617 121.37 127.766 120.147C127.973 119.952 128.171 119.747 128.356 119.531Z" />
      <path d="M125.084 155.471C124.091 154.052 122.682 152.988 121.058 152.432C119.435 151.876 117.679 151.856 116.043 152.376C115.659 152.505 115.285 152.664 114.923 152.848C114.363 153.138 113.829 153.476 113.327 153.859C111.864 154.96 110.633 156.392 109.511 157.827C109.226 158.193 108.966 158.68 108.628 158.993L102.982 166.886C99.7818 171.307 96.626 175.74 93.4966 180.243C91.4546 183.15 89.688 185.605 88.2932 187.777C88.0287 188.186 87.7543 188.643 87.5028 189.007C85.8305 191.636 84.8839 193.555 84.3978 195.264C84.0325 196.4 83.9168 197.603 84.059 198.789C84.2147 200.024 84.6239 201.211 85.2601 202.275C85.5985 202.808 85.9633 203.325 86.353 203.821C86.7577 204.298 87.1879 204.752 87.641 205.182C88.125 205.651 88.6425 206.083 89.19 206.475C93.0869 209.23 97.3531 211.21 101.84 212.74C105.575 214 109.457 214.754 113.385 214.982C114.054 215.016 114.724 215.002 115.39 214.938C116.007 214.883 116.622 214.796 117.23 214.676C117.839 214.531 118.439 214.354 119.028 214.145C120.176 213.708 121.22 213.032 122.095 212.161C122.923 211.318 123.56 210.302 123.962 209.183C124.614 207.53 125.044 205.431 125.326 202.315C125.351 201.872 125.413 201.34 125.456 200.853C125.679 198.268 125.781 195.23 125.945 191.66C126.221 186.167 126.437 180.7 126.609 175.217C126.609 175.217 126.972 165.472 126.971 165.467C127.054 163.221 126.986 160.733 126.374 158.497C126.104 157.425 125.669 156.404 125.084 155.471Z" />
      <path d="M190.683 170.521C189.484 169.207 187.785 167.896 185.105 166.277C184.718 166.061 184.265 165.774 183.846 165.523C181.615 164.182 178.927 162.771 175.787 161.067C170.963 158.42 166.137 155.84 161.265 153.282L152.655 148.719C152.209 148.589 151.755 148.267 151.335 148.065C149.681 147.274 147.928 146.536 146.108 146.198C145.48 146.077 144.844 146.011 144.205 146.001C143.793 145.996 143.382 146.02 142.974 146.075C141.252 146.342 139.659 147.145 138.42 148.37C137.182 149.595 136.361 151.179 136.076 152.898C135.944 153.99 135.984 155.097 136.194 156.177C136.615 158.466 137.638 160.731 138.696 162.715L143.294 171.324C145.852 176.181 148.441 181.009 151.095 185.822C152.807 188.961 154.232 191.648 155.566 193.875C155.819 194.293 156.106 194.745 156.322 195.132C157.947 197.809 159.252 199.494 160.575 200.703C161.433 201.53 162.46 202.16 163.586 202.549C164.77 202.94 166.022 203.079 167.263 202.957C167.894 202.882 168.521 202.773 169.14 202.632C169.751 202.468 170.352 202.273 170.941 202.046C171.578 201.807 172.197 201.521 172.793 201.192C176.288 199.234 179.509 196.823 182.374 194.023C185.811 190.644 188.848 186.959 191.21 182.741C191.539 182.144 191.823 181.525 192.062 180.887C192.285 180.297 192.477 179.695 192.639 179.086C192.779 178.466 192.886 177.839 192.96 177.208C193.078 175.969 192.934 174.719 192.537 173.54C192.148 172.41 191.515 171.379 190.683 170.521Z" />
    </g>
    <defs>
      <clipPath id="clip0_110_45">
        <rect width="250" height="250" fill="white" />
      </clipPath>
    </defs>
  </SvgIcon>
)
export default YelpIcon
