import React, { useContext, useState, useEffect } from 'react'
import Menu from '@material-ui/core/Menu'
import type { WithStyles, Theme } from '@material-ui/core/styles'
import { createStyles, withStyles } from '@material-ui/core/styles'
import ActionIcon from '@material-ui/icons/MoreHoriz'
import moment from 'moment'
import CancelIcon from '@material-ui/icons/Cancel'
import CheckIcon from '@material-ui/icons/Check'
import { IconButton, MenuItem, useClientOptions } from '@ui/paintscout'
import {
  setDate,
  expiryEnabled,
  toggleExpiry,
  getObjectLabels,
  getExpiryDate,
  setExpiryDate
} from '@paintscout/util/builder'
import { QuoteContext } from '../../../context/QuoteContext'
import classnames from 'classnames'
import HeaderCardDatePicker from '../HeaderCardDatePicker'

const styles = (theme: Theme) =>
  createStyles({
    root: {
      position: 'relative'
    },
    expiryRoot: {
      marginTop: theme.spacing(1)
    },
    contents: {},
    editable: {},
    input: {
      ...theme.typography.body1,
      padding: 0,
      '& $inputDisabled': {
        color: `${theme.palette.common.black} `,
        padding: 0
      }
    },
    inputDisabled: {
      pointerEvents: 'none'
    },
    expiryTitle: {
      ...theme.typography.overline
    },
    expiryTitleExpired: {
      color: theme.palette.error.main
    },
    actionIcon: {
      '& svg': {
        fill: theme.palette.grey[500]
      },
      position: 'absolute',
      right: '-6px',
      marginTop: '5px',
      zIndex: 100
    }
  })

export interface QuoteDateCardProps extends WithStyles<typeof styles> {}

function QuoteDateCard({ classes }: QuoteDateCardProps) {
  const { options } = useClientOptions()
  const { quote, updateQuote, isEditable } = useContext(QuoteContext)
  const [anchorEl, setAnchorEl] = useState(null)
  const objectLabels = getObjectLabels({ options, invoice: quote.is_invoice })
  const expiryTerm = quote.is_invoice ? 'Due Date' : 'Expiry'
  const expiryField = quote.is_invoice ? 'customerInvoiceExpiry' : 'customerViewExpiry'
  const settingsExpiryEnabled = options.options?.quotes?.[expiryField]?.enabled || false
  const [enabledQuoteExpiry, setEnabledQuoteExpiry] = useState<boolean>(expiryEnabled({ quote, options }))
  const validExpiryStatus = !['paid', 'accepted', 'invoiced'].includes(quote.status.value)
  // If no expiry set on quote, we leave unset
  const [currentExpiryDate, setCurrentExpiryDate] = useState(getExpiryDate({ quote }))
  const [isExpired, setIsExpired] = useState(
    enabledQuoteExpiry && validExpiryStatus && currentExpiryDate ? currentExpiryDate <= Date.now() : false
  )

  useEffect(() => {
    setIsExpired(enabledQuoteExpiry && validExpiryStatus && currentExpiryDate ? currentExpiryDate <= Date.now() : false)
  }, [currentExpiryDate])

  useEffect(() => {
    setCurrentExpiryDate(getExpiryDate({ quote }))
  }, [quote])

  function handleDateChange(date: moment.Moment) {
    updateQuote({ quote: setDate({ quote, date: date.unix() * 1000 }) })
    setAnchorEl(null)
  }

  function handleExpiryChange(date: moment.Moment) {
    const newExpiry = date.endOf('day').unix() * 1000
    setCurrentExpiryDate(newExpiry)
    updateQuote({ quote: setExpiryDate({ quote, expiryDate: newExpiry }) })
    setAnchorEl(null)
  }

  function handleToggleExpiry(toggle: boolean) {
    updateQuote({ quote: toggleExpiry({ quote, enabled: toggle }) })
    setEnabledQuoteExpiry(toggle)
    setAnchorEl(null)
  }

  function handleClose(event: React.MouseEvent<HTMLElement>) {
    event.stopPropagation()
    setAnchorEl(null)
  }

  function handleClick(event: React.MouseEvent) {
    event.stopPropagation()
    if (isEditable) {
      setAnchorEl(event.currentTarget)
    }
  }

  const getExpiryTitle = () => {
    if (quote.is_invoice) {
      return isExpired ? 'Overdue' : 'Due'
    }
    return isExpired ? 'Expired' : 'Expires'
  }

  return (
    <div className={classes.root}>
      {isEditable && settingsExpiryEnabled && (
        <div className={classes.actionIcon}>
          <IconButton size="small" onClick={handleClick}>
            <ActionIcon />
          </IconButton>
          <Menu
            id="date-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
          >
            {enabledQuoteExpiry && (
              <MenuItem icon={CancelIcon} onClick={() => handleToggleExpiry(false)}>
                Disable {objectLabels.quote.value} {expiryTerm}
              </MenuItem>
            )}
            {!enabledQuoteExpiry && (
              <MenuItem icon={CheckIcon} onClick={() => handleToggleExpiry(true)}>
                Enable {objectLabels.quote.value} {expiryTerm}
              </MenuItem>
            )}
          </Menu>
        </div>
      )}
      <HeaderCardDatePicker
        date={moment(quote.quote_date)}
        onChange={handleDateChange}
        label={'Date'}
        PopperProps={{
          placement: 'bottom-end',
          disablePortal: true
        }}
        modalBreakpoint="md"
        isEditable={isEditable}
      />
      {enabledQuoteExpiry && (
        <HeaderCardDatePicker
          date={currentExpiryDate ? moment(currentExpiryDate) : null}
          onChange={handleExpiryChange}
          label={getExpiryTitle()}
          PopperProps={{
            placement: 'bottom-end'
          }}
          modalBreakpoint="md"
          isEditable={isEditable}
          showBorder={false}
          headerCardClasses={{
            title: classnames(classes.expiryTitle, isExpired && classes.expiryTitleExpired)
          }}
        />
      )}
    </div>
  )
}

export default withStyles(styles)(QuoteDateCard)
