import isEmpty from 'lodash/isEmpty'
import type { OptionsDocument, QuoteDocument, QuoteOptions } from 'paintscout'
import type { RenderableSubItem } from '../../..'
import { getItemDetails } from './get-item-details'
import type { QuoteItemConsumer, QuoteItemSection, RenderableItemSubstrate } from '../../../..'
import { getItemLabel } from './get-item-label'

interface FormatAreaSubstrateProps {
  key?: string
  quote: QuoteDocument
  areaKey?: string
  item: RenderableItemSubstrate
  clientOptions: OptionsDocument
  options: QuoteOptions
  section: QuoteItemSection
  consumer?: QuoteItemConsumer
}

/**
 * @description Format an areaSubstrate in RenderableItemSubstrate form into a RenderableSubItem for display
 * @returns RenderableSubItem
 */
export const formatAreaSubstrate = ({
  key,
  quote,
  areaKey,
  item,
  options,
  clientOptions,
  section,
  consumer
}: FormatAreaSubstrateProps): RenderableSubItem => {
  const { clientLabel, itemName, totalPrice, hours, prep } = getItemDetails({
    item,
    quote
  })

  // Get item label/description
  const labelString = getItemLabel({
    options: clientOptions,
    item,
    quoteOptions: options,
    section,
    totalPrice
  })

  const name = `${clientLabel ? clientLabel : itemName}`
  const firstLine = `${name}${labelString}`
  const secondLine = `${
    item?.areaReportText !== '<p></p>' &&
    !isEmpty(item.areaReportText) &&
    (options?.stackSubstrates || options?.showDetailedBreakdown)
      ? ` ${item.areaReportText}`
      : ''
  }`

  const fullName = firstLine + secondLine
  // formally had description logic, always resulted in '',
  // not sure we need??
  const description = ''

  const r: RenderableSubItem = {
    key,
    name: fullName,
    description,
    value: totalPrice === 0 ? '' : totalPrice,
    additionalFields: {
      areaKey,
      consumer,
      painting: hours,
      prep
    }
  }

  return r
}
