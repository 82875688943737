import { removeStoredMutationsById } from '../graphql/util/removeStoredMutationsById'
import type {
  AppDocsQuery,
  RecoverInvoiceMutationVariables,
  RequestPaymentMutationVariables,
  RequestReviewMutationVariables,
  SendQuoteMutationVariables
} from '@paintscout/api'
import { useRequestPaymentMutation, useSyncQuoteMutation } from '@paintscout/api'
import {
  useRequestReviewMutation,
  useRecoverInvoiceMutation,
  ActivitiesForDocDocument,
  AppDocsDocument,
  SearchQuotesDocument,
  updateCachedQuote,
  updateCachedQuotePartially,
  useArchiveQuotesMutation,
  useDeleteQuotesMutation,
  useSaveQuoteMutation,
  useSendQuoteMutation
} from '@paintscout/api'
import { useUser } from '@ui/paintscout'
import { TRIAL_QUOTE_LIMIT } from '@paintscout/util'
import type ApolloCache from '@wora/apollo-cache'
import { getOperationName } from 'apollo-link'
import type { QuoteDocument } from 'paintscout'
import { useMemo } from 'react'
import { useApolloClient, useMutation } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'
import LogRocket from 'logrocket'

const ACCEPT_QUOTE_QUERY = gql`
  mutation ($quote: JSON!, $signature: String, $quoteId: String) {
    acceptQuote(quote: $quote, signature: $signature, quoteId: $quoteId) {
      quote
    }
  }
`
const ACCEPT_ADDITIONAL_WORK_QUERY = gql`
  mutation acceptAdditionalWork($rev: String!, $signature: String, $quoteId: String) {
    acceptAdditionalWork(rev: $rev, signature: $signature, quoteId: $quoteId) {
      quote
    }
  }
`
/**
 * Returns common useMutations for saving quotes and handles updating the caches
 */
export function useQuoteMutations() {
  const { isTrial } = useUser()
  const [saveQuoteMutation] = useSaveQuoteMutation()
  const [deleteQuotesMutation] = useDeleteQuotesMutation()
  const [archiveQuotesMutation] = useArchiveQuotesMutation()
  const [sendQuoteMutation] = useSendQuoteMutation()
  const [requestReviewMutation] = useRequestReviewMutation()
  const [requestPaymentMutation] = useRequestPaymentMutation()
  const [recoverInvoiceMutation] = useRecoverInvoiceMutation()
  const [syncQuoteMutation] = useSyncQuoteMutation()
  const [acceptQuoteMutation] = useMutation(ACCEPT_QUOTE_QUERY)
  const [acceptAdditionalWorkMutation] = useMutation(ACCEPT_ADDITIONAL_WORK_QUERY)

  const client = useApolloClient()

  return useMemo(
    () => ({
      async saveQuote({ quote, force }: { quote: QuoteDocument; force?: boolean }) {
        const timeOfSave = Date.now()

        // if mutation is queued, this will be the id so we can combine multiple quote saves later
        const mutationId = `save-quote-${quote._id}`

        const quoteResult = await saveQuoteMutation({
          variables: {
            quote,
            force
          },
          context: {
            __mutation_id__: mutationId
          },
          optimisticResponse: {
            saveQuote: makeQuoteOptimistic(quote)
          },
          // getLastActivity is a query from QuoteSidebar
          refetchQueries: [getOperationName(ActivitiesForDocDocument), 'getLastActivity'],
          update(cache: ApolloCache, { data }) {
            const savedQuote = makeQuoteOptimistic(data?.saveQuote)

            if (savedQuote) {
              updateCachedQuote(cache, savedQuote)
            }

            // update trial remainingQuotes
            if (!quote._rev && isTrial) {
              const appDocsQuery = cache.readQuery<AppDocsQuery>({ query: AppDocsDocument })

              if (appDocsQuery.appDocs.remainingQuotes) {
                cache.writeQuery<AppDocsQuery>({
                  query: AppDocsDocument,
                  data: {
                    ...appDocsQuery,
                    appDocs: {
                      ...appDocsQuery.appDocs,
                      // Don't let remainingQuotes go below 0
                      remainingQuotes: Math.max(appDocsQuery.appDocs.remainingQuotes - 1, 0)
                    }
                  }
                })
              }
            }
          }
        })

        // remove any previous offline saveQuote mutations for this quote
        removeStoredMutationsById(client, mutationId, { before: timeOfSave })

        return {
          quote: quoteResult.data.saveQuote
        }
      },

      async deleteQuotes(quoteIds: string[], softDelete?: boolean) {
        LogRocket.track('Quote(s) Deleted', { quoteIds })
        return deleteQuotesMutation({
          variables: { ids: quoteIds, softDelete },
          optimisticResponse: {
            deleteQuotes: quoteIds.map((id) => ({
              __typename: 'BulkDocsResponseObject',
              _id: id,
              _rev: null,
              error: null,
              reason: null
            }))
          },
          refetchQueries: [
            getOperationName(SearchQuotesDocument) // update remainingQuotes
          ],
          update(cache: ApolloCache, { data: _data }) {
            quoteIds.forEach((id) => {
              // we can fake the quote document as just an object with _deleted flag
              updateCachedQuotePartially(cache, id, {
                _deleted: !softDelete,
                trashed: softDelete
              })
            })

            // update trial remainingQuotes
            if (isTrial) {
              const appDocsQuery = cache.readQuery<AppDocsQuery>({ query: AppDocsDocument })

              if (appDocsQuery.appDocs.remainingQuotes >= 0) {
                cache.writeQuery<AppDocsQuery>({
                  query: AppDocsDocument,
                  data: {
                    ...appDocsQuery,
                    appDocs: {
                      ...appDocsQuery.appDocs,
                      // don't let it exceed 10 because they might be deleting the example quotes, which aren't countd towards the limit
                      remainingQuotes: Math.min(
                        appDocsQuery.appDocs.remainingQuotes + quoteIds.length,
                        TRIAL_QUOTE_LIMIT
                      )
                    }
                  }
                })
              }
            }
          }
        })
      },

      async archiveQuotes(quoteIds: string[], { unarchive }: { unarchive?: boolean } = {}) {
        return archiveQuotesMutation({
          variables: { ids: quoteIds, unarchive },
          refetchQueries: [getOperationName(SearchQuotesDocument)],
          optimisticResponse: {
            archiveQuotes: quoteIds.map((id) => ({
              __typename: 'BulkDocsResponseObject',
              _id: id,
              _rev: null,
              error: null,
              reason: null
            }))
          },
          update(cache: ApolloCache, { data: _data }) {
            quoteIds.forEach((id) => {
              // we can fake the quote document as just an object with _deleted flag
              updateCachedQuotePartially(cache, id, { archived: !unarchive })
            })
          }
        })
      },

      async acceptAdditionalWork({ rev, quoteId, signature }: { rev: string; quoteId: string; signature?: string }) {
        return acceptAdditionalWorkMutation({
          variables: {
            rev,
            signature,
            quoteId
          }
        })
      },

      async acceptQuote({ quote, quoteId, signature }: { quote: QuoteDocument; quoteId: string; signature?: string }) {
        return acceptQuoteMutation({
          variables: {
            quote,
            signature,
            quoteId
          }
        })
      },

      async requestReview({ sms, content }: RequestReviewMutationVariables) {
        return requestReviewMutation({
          variables: {
            sms,
            content
          }
        })
      },

      async requestPayment(variables: RequestPaymentMutationVariables) {
        return requestPaymentMutation({
          variables
        })
      },

      async recoverInvoice({ id }: RecoverInvoiceMutationVariables) {
        return recoverInvoiceMutation({
          variables: {
            id
          }
        })
      },

      async syncQuote({ id, rev }: { id: string; rev: string }) {
        return syncQuoteMutation({
          variables: {
            id,
            rev
          }
        })
      },

      async sendQuote(
        quoteId: string,
        { emails, view }: { emails: SendQuoteMutationVariables['emails']; view: string; app?: string }
      ) {
        return sendQuoteMutation({
          context: {
            onlineOnly: true
          },
          variables: {
            id: quoteId,
            emails,
            view
          },
          refetchQueries: [getOperationName(ActivitiesForDocDocument), 'getLastActivity'],
          update(cache: ApolloCache, { data }) {
            if (data) {
              updateCachedQuote(cache, data.sendQuote.quote)
            }
          }
        })
      }
    }),
    []
  )
}

function makeQuoteOptimistic(quote: QuoteDocument) {
  if (!quote) {
    return null
  }

  return {
    ...quote,
    __typename: 'JSON',
    // null is important, if its undefined it will break cache for quote (undefined is not valid graphql value)
    _rev: quote._rev || null,
    number: quote.number ?? null
  }
}
