import React, { forwardRef, useRef, useImperativeHandle, useEffect } from 'react'
import type { Theme } from '@material-ui/core'
import { FormControl, makeStyles } from '@material-ui/core'
import { Editor } from '../Editor'
import type { EditorRef, ToolbarConfig } from '../Editor'
import type { OverridableValue } from 'paintscout'
import { TipTapResetToolbar } from '../Editor/TipTapResetToolbar'
import type { StyleClasses } from '@ui/core/theme/createTheme'
import { trimHtmlInnerWhitespace, trimHtmlTrailingWhitespace } from '@paintscout/util/util'
import striptags from 'striptags'

export interface OverridableTextAreaProps {
  classes?: StyleClasses<typeof useStyles>
  value?: OverridableValue
  required?: boolean
  label?: string
  sublabel?: string
  disabled?: boolean
  toolbar?: ToolbarConfig
  allowFormatting?: boolean
}

export const useStyles = makeStyles<Theme, OverridableTextAreaProps>({
  root: {},
  editorRoot: ({ allowFormatting }) => ({
    minHeight: allowFormatting ? '10em' : '4em'
  })
})

/**
 * Renders a Input component from material-ui and provides extra props for adding an icon
 */
const OverridableTextArea = forwardRef((props: OverridableTextAreaProps, ref) => {
  const { label, sublabel, required, disabled, toolbar, allowFormatting } = props
  const classes = useStyles(props)
  const editorRef: EditorRef = useRef(null)
  const value = useRef(props.value)

  useEffect(() => {
    const editorValue = editorRef.current?.getHTML()

    // I'm not exactly sure when/why Editor.getHTML
    // is returning undefined, but this was the case.
    // When that happens, we're just assuming that the
    // editor isn't ready yet.
    if (typeof editorValue === 'undefined') {
      return
    }

    const currentDefault = props.value.default as string
    const currentCustom = props.value?.custom as string

    const { useCustom } = getUseCustom({
      editorValue,
      currentDefault: currentDefault,
      currentCustom
    })

    value.current = {
      ...value.current,
      useCustom,
      default: currentDefault
    }

    if (!useCustom) {
      editorRef.current?.setHTML(currentDefault)
    }
  }, [props.value.default])

  useImperativeHandle(ref, () => ({
    getValue: () => {
      const editorValue = editorRef.current?.getHTML()
      const currentCustom = value.current?.custom ? String(value.current.custom) : undefined
      const currentDefault = value.current?.default ? String(value.current.default) : undefined

      // If we dont allow formatting, we want to strip the <p> tags from the string so we dont incorrectly think its custom
      const { useCustom, custom } = allowFormatting
        ? getUseCustom({ editorValue, currentCustom, currentDefault })
        : getUseCustom({
            editorValue: striptags(editorValue),
            currentCustom: striptags(currentCustom),
            currentDefault: striptags(currentDefault)
          })

      return {
        ...value.current,
        useCustom,
        custom
      }
    },
    setValue: (value: string) => {
      editorRef.current?.setHTML(value)
    }
  }))

  return (
    <FormControl classes={{ root: classes.root }} fullWidth={true} required={required}>
      <Editor
        classes={{ root: classes.editorRoot, editor: classes.htmlEditor, editorContent: classes.editorContent }}
        label={label}
        sublabel={sublabel}
        content={(value.current?.useCustom ? value.current?.custom : value.current?.default) as string}
        required={required}
        disabled={disabled}
        onReset={disabled ? null : handleResetDescription}
        ref={editorRef}
        toolbar={toolbar}
        Toolbar={allowFormatting ? undefined : TipTapResetToolbar}
      />
    </FormControl>
  )

  function getUseCustom({
    editorValue,
    currentDefault,
    currentCustom
  }: {
    editorValue: string
    currentDefault: string
    currentCustom: string
  }): { useCustom: boolean; custom?: string } {
    // We were having mismatches between the value being "&"
    // and the editor returning "&amp", htmlDecode takes care of that.
    const cleanEditorValue = editorValue
      ? replaceLtGt(trimHtmlTrailingWhitespace(trimHtmlInnerWhitespace(htmlDecode(editorValue))))
      : undefined
    const cleanDefault = currentDefault
      ? replaceLtGt(trimHtmlTrailingWhitespace(trimHtmlInnerWhitespace(currentDefault)))
      : undefined
    const cleanCustom = currentCustom ? trimHtmlTrailingWhitespace(trimHtmlInnerWhitespace(currentCustom)) : undefined
    const useCustom = normalizeWhitespace(cleanEditorValue) !== normalizeWhitespace(cleanDefault)
    const custom = useCustom ? cleanEditorValue : cleanCustom

    return {
      useCustom,
      custom
    }
  }

  function normalizeWhitespace(input: string) {
    return input?.replace(/\s+/g, ' ')
  }

  // &lt;X and &gtX being replaced with <X and >X, need to swap back so they dont become tags and hide/break things
  function replaceLtGt(html) {
    return html.replace(/<(\d+)/g, '&lt;$1').replace(/(^|[^a-zA-Z"])>(\d+)/g, '$1&gt;$2')
  }

  function htmlDecode(input: string): string {
    if (!input) {
      return input
    }
    const element = document.createElement('textarea')
    element.innerHTML = input
    return element.childNodes.length === 0 ? '' : element.childNodes[0].nodeValue
  }

  function handleResetDescription() {
    value.current = {
      ...value.current,
      useCustom: false
    }
    editorRef.current?.setHTML(value.current?.default as string)
  }
})

OverridableTextArea.displayName = 'OverridableTextArea'

export default OverridableTextArea
