import type { PopperPlacementType, PopperProps, Theme } from '@material-ui/core'
import { ClickAwayListener, FormControl, Popper, makeStyles } from '@material-ui/core'
import type { Variant as TypographyVariant } from '@material-ui/core/styles/createTypography'
import DropdownIcon from '@material-ui/icons/KeyboardArrowDown'
import { useIsMobile } from '@ui/core'
import { useSnackbar } from 'notistack'
import type { ReactNode } from 'react'
import React, { useEffect, useRef, useState } from 'react'
import Grid from '../Grid'
import type { InputFieldProps } from '../InputField'
import InputField from '../InputField'
import InputLabel from '../InputLabel'
import Paper from '../Paper'
import Tooltip from '../Tooltip'
import Typography from '../Typography'
import DropdownSelectFooter from './DropdownSelectFooter'
import DropdownSelectMenuItem from './DropdownSelectMenuItem'
import DropdownSelectSearchBar from './DropdownSelectSearchBar'

const useStyles = makeStyles<Theme, DropdownSelectProps>((theme: Theme) => ({
  inputLabel: {
    marginBottom: '5px'
  },

  tooltipTypography: {
    fontSize: '0.75rem',
    fontWeight: '500'
  },
  toolTipLabel: {
    fontSize: '0.7rem',
    fontWeight: '500',
    marginBottom: '5px'
  },
  toolTipWrapper: {
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'flex-start',
    marginBottom: '3px'
  },
  menuItemsContainer: {
    maxHeight: ({ maxMenuHeight }) => `${maxMenuHeight ?? '300'}px`,
    overflow: 'auto',
    paddingBottom: theme.spacing(1)
  },
  noOptionsMessage: {
    padding: theme.spacing(2),
    color: theme.palette.text.disabled
  },
  icon: {
    cursor: 'pointer',
    borderLeft: ({ nestedDropdown }) => (nestedDropdown ? 'none' : `1px solid ${theme.palette.grey[400]}`),
    color: theme.palette.grey[400],
    marginRight: -theme.spacing(1),
    fontSize: ({ shrink }) => (shrink ? '1.25rem' : '1.5rem'),
    width: ({ shrink }) => (shrink ? '24px' : '36px')
  },
  selectInputRoot: ({ nestedDropdown, typographyVariant }) => ({
    ...theme.typography[typographyVariant],
    margin: 0,
    ...(nestedDropdown
      ? {
          border: 'none',
          backgroundColor: 'transparent'
        }
      : {})
  }),
  selectInput: {
    cursor: 'pointer',
    paddingRight: 0,
    borderLeft: ({ nestedDropdownBorder }) =>
      nestedDropdownBorder ? `1px solid ${theme.palette.grey[400]}` : undefined,
    padding: ({ shrink }) => (shrink ? theme.spacing(0.25, 1) : undefined)
  },
  popper: {
    zIndex: 1400,
    boxShadow: theme.shadows[4]
  },
  sublabel: {
    ...theme.typography.caption,
    color: theme.palette.grey[500]
  },
  paper: {
    paddingTop: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
    boxShadow: 'none'
  },
  groupLabel: {
    padding: theme.spacing(1, 2),
    backgroundColor: theme.palette.grey[100]
  },
  // This is a hack to get the helper text to display above the input.
  // We can use it to apply formatOptionLabel to the selected value.
  helperText: {
    position: 'absolute',
    top: '3%',
    left: 2,
    width: 'calc(100% - 38px)',
    height: '80%',
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing(1),
    // allow clicks to pass through to the input
    pointerEvents: 'none'
  },
  hiddenFocus: {
    border: 'none',
    boxShadow: 'none'
  },
  adornment: {
    marginLeft: 0
  }
}))

export interface DropdownSelectPlaceholder {
  selectPlaceholder?: string // Select component placeholder
  plural?: string // Used in the dropdown's search bar and to handle overflow
}

export interface DropdownSelectOption {
  key?: string
  label: string
  value: any
  dotColor?: string
  disabled?: boolean
  forceTop?: boolean
  [key: string]: any
}

export interface GroupedDropdownSelectOption {
  label: string
  options: DropdownSelectOption[]
}

interface BaseDropdownSelectProps extends Omit<InputFieldProps, 'onChange' | 'placeholder' | 'helperText'> {
  label?: string
  options?: DropdownSelectOption[] | GroupedDropdownSelectOption[]
  autoApply?: boolean
  placeholder?: DropdownSelectPlaceholder
  loading?: boolean
  toolTip?: string
  disabled?: boolean
  searchable?: boolean
  noOptionsMessage?: string
  popperPlacement?: PopperPlacementType
  popperModifiers?: PopperProps['modifiers']
  nestedDropdown?: boolean // For components like the Discount Rate Dropdown
  nestedDropdownBorder?: boolean
  maxMenuHeight?: number
  typographyVariant?: TypographyVariant
  shrink?: boolean
  pagination?: {
    currentPage: number
    pages: ReactNode[]
    onPageChange?: (page: number) => void
  }
  minimumSearchCharacters?: number
  onMinimumSearchCharactersReached?: (searchFilter: string) => void
  validateCreatedOption?: (option: string) => string
  checkInvalid?: () => string
  createLabel?: (val: string) => string
  formatOptionLabel?: (option: DropdownSelectOption, placement?: 'menu-item' | 'selected') => React.ReactNode
  onCreateOption?: (option: string) => void
}

export interface SingleSelectProps extends BaseDropdownSelectProps {
  variant: 'single'
  value: DropdownSelectOption
  onChange: (option: DropdownSelectOption) => void
}

export interface MultiSelectProps extends BaseDropdownSelectProps {
  variant: 'multi'
  value: DropdownSelectOption[]
  onChange: (options: DropdownSelectOption[]) => void
}

export type DropdownSelectProps = SingleSelectProps | MultiSelectProps

export default function DropdownSelect(props: DropdownSelectProps) {
  const classes = useStyles(props)

  const {
    label,
    placeholder,
    toolTip,
    disabled,
    variant,
    loading,
    autoApply = true,
    noOptionsMessage = 'No options',
    nestedDropdown,
    typographyVariant = 'body1',
    formatOptionLabel,
    createLabel,
    validateCreatedOption,
    checkInvalid = () => '',
    onChange: onChangeProp,
    onCreateOption,
    onMinimumSearchCharactersReached,
    popperModifiers,
    pagination,
    popperPlacement = 'bottom-start',
    sublabel,
    minimumSearchCharacters,
    searchable: propsSearchable,
    ...inputProps
  } = props

  const multi = variant === 'multi'
  const searchable = propsSearchable || !!minimumSearchCharacters

  const [anchorEl, setAnchorEl] = useState(null)
  const selectRef = useRef<HTMLInputElement>(null)
  const [searchFilter, setSearchFilter] = useState('')
  const mobile = useIsMobile()
  const { enqueueSnackbar } = useSnackbar()

  const [intermediateValue, setIntermediateValue] = useState<DropdownSelectProps['value']>(
    props.value ?? (multi ? [] : null)
  )

  const optionsAreGrouped = (
    options: DropdownSelectOption[] | GroupedDropdownSelectOption[]
  ): options is GroupedDropdownSelectOption[] => {
    const groupedOptions = (options as GroupedDropdownSelectOption[]) || []
    return groupedOptions.some((option) => optionIsGrouped(option))
  }

  const optionIsGrouped = (
    option: DropdownSelectOption | GroupedDropdownSelectOption
  ): option is GroupedDropdownSelectOption => {
    return (option as GroupedDropdownSelectOption).options !== undefined
  }

  const value = autoApply ? props.value ?? (multi ? [] : null) : intermediateValue

  const calculatedOptions =
    multi && !optionsAreGrouped(props.options)
      ? [
          ...props.options.filter((option) => option.forceTop),
          ...props.value.filter((selectedOption) => !selectedOption.forceTop),
          ...props.options.filter(
            (option) =>
              !(props.value as DropdownSelectOption[]).some((selectedOption) =>
                selectedOption.key ? selectedOption.key === option.key : selectedOption.value === option.value
              ) && !option.forceTop
          )
        ]
      : props.options

  const [options, setOptions] = useState<DropdownSelectProps['options']>(calculatedOptions)

  // whenever the dropdown is opened, reset the options to the calculated options
  useEffect(() => {
    setOptions(calculatedOptions)
  }, [anchorEl, intermediateValue])

  // keep options in sync with calculated options when an option is created
  useEffect(() => {
    if (calculatedOptions.length > options.length) {
      setOptions(calculatedOptions)
    }
  }, [calculatedOptions, options])

  // keep options in sync with calculated options when an option is disabled
  useEffect(() => {
    if (!optionsAreGrouped(calculatedOptions) && !optionsAreGrouped(options)) {
      const optionsNeedToGetDisabled = calculatedOptions.some((calculatedOptions) => {
        const matchingOption = options.find((o) =>
          o.key ? o.key === calculatedOptions.key : o.value === calculatedOptions.value
        )
        return matchingOption && calculatedOptions.disabled !== matchingOption.disabled
      })

      if (optionsNeedToGetDisabled) {
        const calculatedOptionsOrderedByOptions = options.map((option) => {
          const matchingOption = calculatedOptions.find((o) =>
            o.key ? o.key === option.key : o.value === option.value
          )
          return matchingOption || option
        })

        setOptions(calculatedOptionsOrderedByOptions)
      }
    }
  }, [calculatedOptions, options])

  useEffect(() => {
    if (!autoApply) {
      setIntermediateValue(props.value ?? (multi ? [] : null))
    }
  }, [props.value])

  // If we're async the options are changing, so keep them in sync
  useEffect(() => {
    if (minimumSearchCharacters) {
      setOptions(calculatedOptions)
    }
  }, [props.options])

  const getReadableValue = () => {
    if (multi) {
      return (value as DropdownSelectOption[]).map((option) => option.label).join(', ')
    } else {
      return (value as DropdownSelectOption)?.label || ''
    }
  }

  const selectedOptionsAreOverflowing = () => {
    const selectWidth = selectRef?.current?.clientWidth
    if (!selectWidth) {
      return false
    }
    const readableValue = getReadableValue()
    const textWidth = readableValue.length * 8.25 // 8px ish per character plus a buffer
    return textWidth > selectWidth
  }

  const getDisplayValue = () => {
    if (multi && selectedOptionsAreOverflowing()) {
      return `(${(value as DropdownSelectOption[]).length}) ${placeholder?.plural || 'Options'} Selected`
    } else {
      return getReadableValue()
    }
  }

  const optionIsSelected = (option: DropdownSelectOption) => {
    const compareValue = option.key ? 'key' : 'value'
    if (multi) {
      return !!(value as DropdownSelectOption[]).find(
        (selectedOption) => selectedOption[compareValue] === option[compareValue]
      )
    } else {
      return (value as DropdownSelectOption)?.[compareValue] === option[compareValue]
    }
  }

  const isMultiSelectHandler = (onChangeProp: any): onChangeProp is (options: DropdownSelectOption[]) => void => multi

  const isSingleSelectHandler = (onChangeProp: any): onChangeProp is (option: DropdownSelectOption) => void => !multi

  const filterOptions = () => {
    if (minimumSearchCharacters && searchFilter.length < minimumSearchCharacters) {
      return []
    }
    return options.filter((option) => {
      if (optionIsGrouped(option)) {
        const subOptionsSearch = (option as GroupedDropdownSelectOption).options.filter((subOption) =>
          subOption.label.toLowerCase().includes(searchFilter.toLowerCase())
        )
        return subOptionsSearch.length > 0 ? { ...option, options: subOptionsSearch } : false
      } else {
        return option.label.toLowerCase().includes(searchFilter.toLowerCase())
      }
    })
  }

  const onChange = (options: DropdownSelectOption[] | DropdownSelectOption) => {
    if (isMultiSelectHandler(onChangeProp)) {
      onChangeProp(options as DropdownSelectOption[])
    } else if (isSingleSelectHandler(onChangeProp)) {
      onChangeProp((Array.isArray(options) ? options[0] : options) as DropdownSelectOption)
    }
  }

  const handleChange = (options) => {
    if (autoApply) {
      onChange(options)
    } else {
      setIntermediateValue(options)
    }
  }

  const handleOptionClick = (option) => {
    if (multi) {
      if (optionIsSelected(option)) {
        handleChange(
          (value as DropdownSelectOption[]).filter((selectedOption) =>
            selectedOption.key ? selectedOption.key !== option.key : selectedOption.value !== option.value
          )
        )
      } else {
        handleChange([...(value as DropdownSelectOption[]), option])
      }
    } else {
      handleChange([option])
      if (!pagination) {
        handleApply({ allowCreateOption: false })
      }
    }
  }

  const handleApply = ({ allowCreateOption }: { allowCreateOption: boolean }) => {
    const invalidReason = checkInvalid()
    if (invalidReason.length) {
      enqueueSnackbar(invalidReason, { variant: 'error' })
      return
    }

    if (onCreateOption && searchable && !!searchFilter.trim() && allowCreateOption) {
      const invalidMessage = validateCreatedOption?.(searchFilter)
      if (invalidMessage) {
        enqueueSnackbar(invalidMessage, { variant: 'error' })
        return
      }
      onCreateOption(searchFilter)
    }
    if (!autoApply) {
      onChange(intermediateValue)
    }

    setAnchorEl(null)
    clearSearchFilter()
  }

  const handleClear = () => {
    onChange([])
    setIntermediateValue([])
    if (notOnDefaultPage && !!pagination?.onPageChange) {
      pagination.onPageChange(pagination.currentPage - 1)
    } else {
      setAnchorEl(null)
      clearSearchFilter()
    }
  }

  const onCreateClick = () => {
    if (!onCreateOption) {
      return
    }

    const invalidMessage = validateCreatedOption?.(searchFilter)
    if (invalidMessage) {
      enqueueSnackbar(invalidMessage, { variant: 'error' })
      return
    }

    if (optionsAreGrouped(options)) {
      setOptions([{ label: searchFilter, value: searchFilter } as any, ...options])
    }

    onCreateOption(searchFilter)
    setSearchFilter('')
    if (autoApply && !multi) {
      setAnchorEl(null)
    }
  }

  const handleSearchFilterChange = (newSearchFilter: string) => {
    setSearchFilter(newSearchFilter)
    if (
      newSearchFilter.length >= minimumSearchCharacters &&
      !!minimumSearchCharacters &&
      !!onMinimumSearchCharactersReached
    ) {
      onMinimumSearchCharactersReached(newSearchFilter)
    }
  }

  const clearSearchFilter = () => {
    setTimeout(() => setSearchFilter(''), 200) // Delay to allow the popover to close before clearing the search filter
  }

  const handleClickAway = () => {
    if (anchorEl) {
      handleApply({ allowCreateOption: false })
    }
  }

  const notOnDefaultPage = !!pagination && pagination.pages.length && pagination.currentPage !== 0

  const MenuContent = () => {
    if (notOnDefaultPage) {
      return pagination.pages[pagination.currentPage - 1]
    }

    const filteredOptions = filterOptions()
    const showCreateableOption = !!onCreateOption && searchable && !!searchFilter.trim()

    return (
      <>
        {filteredOptions.length > 0 &&
          filteredOptions.map((option: GroupedDropdownSelectOption | DropdownSelectOption, index) => {
            if (optionIsGrouped(option)) {
              return (
                <Grid key={`${option.label}-${index}`} item xs={12}>
                  <Typography variant="h6" className={classes.groupLabel}>
                    {option.label}
                  </Typography>
                  {(option as GroupedDropdownSelectOption).options.map((option, groupedIndex) => (
                    <DropdownSelectMenuItem
                      grouped
                      typographyVariant={typographyVariant}
                      searchable={searchable}
                      key={`${option.key ?? option.value}-${index}-${groupedIndex}`}
                      option={option}
                      showSelectableIcon={multi}
                      formatOptionLabel={(option) => formatOptionLabel?.(option, 'menu-item') || option.label}
                      selected={multi && optionIsSelected(option)}
                      onClick={() => handleOptionClick(option)}
                    />
                  ))}
                </Grid>
              )
            } else {
              const unGroupedOption = option as DropdownSelectOption
              return (
                <Grid key={`${unGroupedOption.value}-${index}`} item xs={12}>
                  <DropdownSelectMenuItem
                    searchable={searchable}
                    typographyVariant={typographyVariant}
                    option={unGroupedOption}
                    formatOptionLabel={props.formatOptionLabel}
                    selected={multi && optionIsSelected(unGroupedOption)}
                    showSelectableIcon={multi}
                    onClick={() => handleOptionClick(unGroupedOption)}
                  />
                </Grid>
              )
            }
          })}
        {showCreateableOption && (
          <Grid key={'create-option'} item xs={12}>
            <DropdownSelectMenuItem
              typographyVariant={typographyVariant}
              searchable={searchable}
              option={{
                label: createLabel ? createLabel(searchFilter) : `Create "${searchFilter}"`,
                value: searchFilter
              }}
              selected
              onClick={onCreateClick}
            />
          </Grid>
        )}
        {!showCreateableOption && !filteredOptions.length && (
          <Grid className={classes.noOptionsMessage} container justifyContent="center">
            <Typography variant="caption">
              {!!minimumSearchCharacters && searchFilter.length < minimumSearchCharacters
                ? `Search term must be ${minimumSearchCharacters} characters or more.`
                : noOptionsMessage}
            </Typography>
          </Grid>
        )}
      </>
    )
  }

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <FormControl fullWidth>
        {label &&
          (toolTip ? (
            <>
              <div className={classes.toolTipWrapper}>
                <Tooltip interactive={typeof toolTip !== 'string'} content={toolTip}>
                  <Typography className={classes.tooltipTypography} variant="overline">
                    {label}
                    {true && <span style={{ color: 'red' }}> *</span>}
                  </Typography>
                </Tooltip>
              </div>
              {sublabel && <InputLabel variant="sublabel">{sublabel}</InputLabel>}
            </>
          ) : (
            <>
              <InputLabel
                required={inputProps.required}
                className={sublabel ? null : classes.inputLabel}
                disabled={disabled || loading}
                shrink
              >
                {label}
              </InputLabel>
              {sublabel && <InputLabel variant="sublabel">{sublabel}</InputLabel>}
            </>
          ))}

        <InputField
          {...inputProps}
          endAdornment={<DropdownIcon className={classes.icon} onClick={handleClickAway} />}
          disabled={disabled || loading}
          classes={{
            root: classes.selectInputRoot,
            helperText: inputProps.error ? null : classes.helperText,
            input: classes.selectInput,
            focused: nestedDropdown ? classes.hiddenFocus : null,
            adornment: classes.adornment
          }}
          value={getDisplayValue()}
          placeholder={loading ? 'Loading...' : placeholder?.selectPlaceholder || 'Select'}
          inputRef={selectRef}
          fullWidth
          onTouchStart={(e) => {
            e.preventDefault()
          }}
          onMouseDown={(e) => {
            e.preventDefault()
          }}
          onClick={(e) => {
            if (!anchorEl) {
              setAnchorEl(e.currentTarget)
            }
          }}
          helperText={formatOptionLabel && value ? formatOptionLabel(value as DropdownSelectOption, 'selected') : ''}
        />
        <Popper
          open={!!anchorEl && !disabled && !loading}
          className={classes.popper}
          anchorEl={anchorEl}
          placement={popperPlacement}
          disablePortal
          modifiers={popperModifiers}
        >
          <Paper className={classes.paper} style={{ width: selectRef?.current?.clientWidth + (mobile ? 42 : 36) }}>
            {searchable && !notOnDefaultPage && (
              <DropdownSelectSearchBar
                value={searchFilter}
                placeholder={placeholder?.plural ? `Search ${placeholder.plural}` : 'Search'}
                onChange={handleSearchFilterChange}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' && !!searchFilter.trim() && !!onCreateOption) {
                    onCreateClick()
                  }
                }}
              />
            )}
            <Grid container className={classes.menuItemsContainer}>
              {MenuContent()}
            </Grid>
            {(multi || !autoApply || notOnDefaultPage) && (
              <DropdownSelectFooter
                clearLabel={notOnDefaultPage ? 'Back' : 'Clear'}
                onApply={() => handleApply({ allowCreateOption: true })}
                onClear={handleClear}
              />
            )}
          </Paper>
        </Popper>
      </FormControl>
    </ClickAwayListener>
  )
}
