import AboutEstimator from './AboutEstimator'
import Awards from './Awards'
import Cover from './Cover'
import Custom from './Custom'
import Divider from './Divider'
import Documents from './Documents'
import Image from './Image'
import SliderImage from './SliderImage'
import Introduction from './Introduction'
import Gallery from './Gallery'
import Mission from './Mission'
import Pictures from './Pictures'
import Quote from './Quote'
import Services from './Services'
import Terms from './Terms'
import Testimonials from './Testimonials'
import Text from './Text'
import TwoColumn from './TwoColumn'
import ThreeColumn from './ThreeColumn'
import WorkOrder from './WorkOrder'
import Socials from './Socials'

// Sherwin Williams
import ColorHelp from './partners/SherwinWilliams/ColorHelp'
import ColorSamples from './partners/SherwinWilliams/ColorSamples'
import PopularColors from './partners/SherwinWilliams/PopularColors'

// Benjamin Moore
import MainPage from './partners/BenjaminMore/MainPage'

export default {
  'about-estimator': AboutEstimator,
  awards: Awards,
  cover: Cover,
  custom: Custom,
  divider: Divider,
  documents: Documents,
  image: Image,
  sliderImage: SliderImage,
  introduction: Introduction,
  gallery: Gallery,
  mission: Mission,
  pictures: Pictures,
  quote: Quote,
  services: Services,
  terms: Terms,
  testimonials: Testimonials,
  text: Text,
  socials: Socials,
  '2-column': TwoColumn,
  '3-column': ThreeColumn,
  'work-order': WorkOrder,
  'sherwin-williams-popular-colors': PopularColors,
  'sherwin-williams-color-samples': ColorSamples,
  'sherwin-williams-color-help': ColorHelp,
  'benjamin-moore-main': MainPage
}
