import type { QuoteDocument, OptionsDocument } from 'paintscout'
import { getQuoteTypeOptions } from '../../builder/options'

type QuoteTypeSubTotals = {
  [key: string]: {
    price: number
    hours: number
    materials: number
    labor: number
    prep: number
  }
}

export const getQuoteTypeSubTotals = (args: { quote: QuoteDocument; options: OptionsDocument }): QuoteTypeSubTotals => {
  const { quote, options } = args
  let subTotals = {
    int: {
      price: 0,
      hours: 0,
      materials: 0,
      labor: 0,
      prep: 0
    },
    ext: {
      price: 0,
      hours: 0,
      materials: 0,
      labor: 0,
      prep: 0
    },
    all: {
      price: 0,
      hours: 0,
      materials: 0,
      labor: 0,
      prep: 0
    }
  }

  if (!quote) {
    return subTotals
  }

  const lineItems = Object.keys(quote.lineItems ?? {})
  const areas = Object.keys(quote.areas ?? {})
  const groups = Object.keys(quote.groups ?? {})
  const quoteTypeOptions = getQuoteTypeOptions({ options })

  if (lineItems.length > 0) {
    lineItems.forEach((lineItemKey) => {
      const lineItem = quote.lineItems[lineItemKey]
      if (lineItem) {
        const quoteType =
          lineItem?.quoteType && quoteTypeOptions.find((option) => option.value === lineItem?.quoteType)
            ? lineItem.quoteType
            : 'all'
        const { afterMaterials: price = 0, materials = 0 } = lineItem?.totals ?? {}
        const { hours = 0 } = lineItem
        const labor = price - materials

        if (lineItem.use_total) {
          subTotals = {
            ...subTotals,
            [quoteType]: subTotals[quoteType]
              ? {
                  price: (subTotals[quoteType]?.price ?? 0) + price,
                  materials: (subTotals[quoteType]?.materials ?? 0) + materials,
                  hours: (subTotals[quoteType]?.hours ?? 0) + hours,
                  prep: subTotals[quoteType]?.prep ?? 0,
                  labor: (subTotals[quoteType]?.labor ?? 0) + labor
                }
              : {
                  price,
                  materials,
                  hours,
                  prep: 0,
                  labor
                }
          }
        }
      }
    })
  }

  if (areas.length > 0) {
    areas.forEach((areaKey) => {
      const area = quote.areas[areaKey]

      const { totals, int_ext } = area
      const {
        price,
        override_price_value,
        override_price,
        materials,
        override_materials,
        override_materials_value,
        hours,
        prep
      } = totals

      const materialsPrice = override_materials ? override_materials_value : materials
      const adjustedPrice = override_price ? override_price_value : price
      const totalPrice = adjustedPrice + materialsPrice

      const quoteType =
        int_ext?.value && quoteTypeOptions.find((option) => option.value === int_ext?.value) ? int_ext?.value : 'all'

      if (area && area.use_total) {
        subTotals = {
          ...subTotals,
          [quoteType]: subTotals[quoteType]
            ? {
                price: (subTotals[quoteType]?.price ?? 0) + totalPrice,
                materials: (subTotals[quoteType]?.materials ?? 0) + materialsPrice,
                hours: (subTotals[quoteType]?.hours ?? 0) + hours,
                prep: (subTotals[quoteType]?.prep ?? 0) + prep,
                labor: (subTotals[quoteType]?.labor ?? 0) + adjustedPrice
              }
            : {
                price: totalPrice,
                materials: materialsPrice,
                labor: adjustedPrice,
                hours,
                prep
              }
        }
      }
    })
  }

  if (groups.length > 0) {
    const groupChildCache = {}
    groups.forEach((groupKey) => {
      const group = quote.groups[groupKey]

      group.children.forEach((child) => {
        if (quote?.substrates[child] && !groupChildCache[child]) {
          // If substrate, add totals to group
          const substrate = quote?.substrates[child]

          let quoteType
          if (substrate.int_ext && substrate.int_ext.value) {
            quoteType = substrate.int_ext.value
          }

          quoteType = quoteType && quoteTypeOptions.find((option) => option.value === quoteType) ? quoteType : 'all'
          const { price = 0, materials = 0, hours = 0, prep = 0 } = substrate?.grouped_totals ?? {}
          const labor = price - materials
          // const price = substrate?.grouped_totals?.price ?? 0

          subTotals = {
            ...subTotals,
            [quoteType]: subTotals[quoteType]
              ? {
                  price: (subTotals[quoteType]?.price ?? 0) + price,
                  materials: (subTotals[quoteType]?.materials ?? 0) + materials,
                  hours: (subTotals[quoteType]?.hours ?? 0) + hours,
                  prep: (subTotals[quoteType]?.prep ?? 0) + prep,
                  labor: (subTotals[quoteType]?.labor ?? 0) + labor
                }
              : {
                  price,
                  materials,
                  labor,
                  hours,
                  prep
                }
          }

          // Cache so we only add grouped_total for this substrate once
          groupChildCache[child] = true
        }
      })
    })
  }

  return subTotals
}
