import React from 'react'
import type { PresentationSectionProps } from '../..'
import { Grid, Typography, ImagePlaceholder } from '@ui/paintscout'
import type { PresentationSection } from 'paintscout'
import { ImgComparisonSlider } from '@img-comparison-slider/react'
import { generateSrcSet } from '@ui/core/util'
import { makeStyles } from '@material-ui/core'
import type { Theme } from '@material-ui/core/styles'

const useStyles = makeStyles<Theme, { maxHeight: number }>(
  (_theme) => ({
    root: {},
    slider: ({ maxHeight }) => ({
      justifyContent: 'center',
      maxHeight,
      height: maxHeight,
      '&:focus': {
        outline: 'none'
      }
    }),
    image: ({ maxHeight }) => ({ maxHeight, minWidth: '100%', height: maxHeight }),
    svg: { margin: '-20px' }
  }),
  { name: 'SliderImage' }
)

export default function SliderImage(props: PresentationSectionProps) {
  const { section, WrapperComponent, showHelp } = props

  const { fields, title } = section

  const { leftImage, rightImage } = fields
  return (
    <WrapperComponent {...props}>
      <Grid container spacing={3} style={{ justifyContent: 'center' }}>
        {title && (
          <Grid item md={12} sm={12} xs={12}>
            <Typography variant={'h2'}>{title}</Typography>
          </Grid>
        )}
        {leftImage && rightImage && <SliderImageContainer section={section} />}
        {!leftImage && !rightImage && showHelp && (
          <Grid item md={12} sm={12} xs={12}>
            <ImagePlaceholder title={'Image'} width={'100%'} height={150} />
          </Grid>
        )}
      </Grid>
      {!title && !leftImage && showHelp && <ImagePlaceholder title={'Image'} width={'100%'} height={150} />}
    </WrapperComponent>
  )
}

const SliderImageContainer = React.memo(function SliderImage({ section }: { section: PresentationSection }) {
  const { fields } = section
  const { leftImage, rightImage } = fields
  const generatedLeftSrc = generateSrcSet({ file: leftImage })
  const generatedRightSrc = generateSrcSet({ file: rightImage })
  const maxHeight = leftImage.height >= rightImage.height ? rightImage.height : leftImage.height
  const classes = useStyles({ maxHeight })

  return (
    <ImgComparisonSlider className={classes.slider}>
      <svg
        // @ts-ignore
        slot="handle"
        xmlns="http://www.w3.org/2000/svg"
        width="100"
        viewBox="-8 -3 16 6"
        className={classes.svg}
      >
        <path
          stroke="#fff"
          d="M -5 -2 L -7 0 L -5 2 M -5 -2 L -5 2 M 5 -2 L 7 0 L 5 2 M 5 -2 L 5 2"
          strokeWidth="1"
          fill="#fff"
          vectorEffect="non-scaling-stroke"
        ></path>
      </svg>
      <img slot="first" src={leftImage.src} alt={leftImage.alt} srcSet={generatedLeftSrc} className={classes.image} />
      <img
        slot="second"
        src={rightImage.src}
        alt={leftImage.alt}
        srcSet={generatedRightSrc}
        className={classes.image}
      />
    </ImgComparisonSlider>
  )
})
