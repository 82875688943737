import React, { useState } from 'react'
import type { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'

import type { QuoteFile } from 'paintscout'

import debounce from 'lodash/debounce'

import type { WithUseStyles } from '../styles'

import UploadImage from '../UploadImage'

import FilePreview from '../FilePreview'

import InputField from '../InputField'
import Tabs from '../Tabs'
import Tab from '../Tab'
import Grid from '../Grid'
import type { StyleClasses } from '@ui/core/theme'

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  placeholder: {
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(3)
    }
  }
}))

export interface EmbedMediaProps extends WithUseStyles<typeof useStyles> {
  value?: QuoteFile
  onChange: (file: QuoteFile) => void

  className?: string
  disabled?: boolean
  accept?: string
  maxWidth?: number
  maxHeight?: number
  buttonHeight?: number
  quality?: number
  hideYoutube?: boolean
}

function EmbedMedia({
  value,
  onChange,
  accept,
  maxWidth,
  maxHeight,
  buttonHeight,
  quality,
  disabled,
  hideYoutube,
  ...props
}: EmbedMediaProps) {
  const tab = value?.format === 'youtube' ? 'youtube' : 'upload'

  return (
    <Grid container spacing={1}>
      <Grid item sm={12}>
        <Tabs value={tab === 'youtube' ? 1 : 0} onChange={handleTabChange}>
          <Tab label="Upload" />
          {!hideYoutube && <Tab label="YouTube" />}
        </Tabs>
      </Grid>
      <Grid item sm={12}>
        {tab === 'upload' && (
          <UploadImage
            cloudinaryPublicId={value?.cloudinaryPublicId}
            s3PublicKey={value?.s3PublicKey}
            src={value?.src}
            onUpload={handleUpload}
            onClear={handleClear}
            quality={quality}
            maxWidth={maxWidth}
            maxHeight={maxHeight}
            buttonHeight={buttonHeight}
            accept={accept}
            disabled={disabled}
          />
        )}
        {tab === 'youtube' && <YouTube {...props} onChange={onChange} value={value} disabled={disabled} />}
      </Grid>
    </Grid>
  )

  function handleTabChange(event: any, value: any) {
    onChange({
      ...value,
      format: value === 1 ? 'youtube' : 'upload'
    })
  }

  async function handleUpload(args: {
    src: string
    cloudinaryPublicId: string
    format: string
    width: number
    height: number
    type: string
  }) {
    onChange({ ...args, visibility: 'visible' })
  }

  function handleClear() {
    onChange(null)
  }
}

export interface YouTubeProps {
  classes?: StyleClasses<typeof useStyles>
  value?: QuoteFile
  onChange: (value: QuoteFile) => void
  disabled?: boolean
}

function YouTube({ onChange, value, disabled, ...props }: YouTubeProps) {
  const classes = useStyles(props)
  const [unparsedUrl, setUnparsedUrl] = useState<string>(value?.youtubeId ?? '')
  const updateYoutubeVideo = debounce((unparsedUrl) => {
    const youtubeId = getYouTubeId(unparsedUrl)
    if (youtubeId) {
      setUnparsedUrl(youtubeId)
      onChange({
        ...value,
        youtubeId
      })
    }
  }, 1000)

  return (
    <Grid container spacing={3}>
      <Grid item sm={12}>
        <InputField
          label={'YouTube URL or ID'}
          disabled={disabled}
          fullWidth={true}
          onChange={handleYouTubeUrlChange}
          value={unparsedUrl}
        />
      </Grid>
      <Grid item sm={12}>
        <FilePreview classes={{ placeholder: classes.placeholder }} file={value} />
      </Grid>
    </Grid>
  )

  function handleYouTubeUrlChange(ev: React.ChangeEvent<HTMLInputElement>) {
    // https://www.youtube.com/watch?v=Y-UqcW4bQLE
    setUnparsedUrl(ev.target.value)
    updateYoutubeVideo(ev.target.value)
  }

  function getYouTubeId(initialUrl) {
    let id = ''
    const url = initialUrl.replace(/(>|<)/gi, '').split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/)
    if (url[2] !== undefined) {
      id = url[2].split(/[^0-9a-z_-]/i)
      id = id[0]
    } else {
      id = url
    }
    return id
  }
}

export default EmbedMedia
