import React from 'react'
import type { SvgIconProps } from '@material-ui/core/SvgIcon'
import SvgIcon from '@material-ui/core/SvgIcon'

const GPlusIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox={'0 0 250 250'} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M125.5 214C174.929 214 215 173.929 215 124.5C215 75.0705 174.929 35 125.5 35C76.0705 35 36 75.0705 36 124.5C36 173.929 76.0705 214 125.5 214ZM167 171.667H135.889V140.555H114.111V171.667H83V78.3333H104.778V100.111L167 118V171.667Z"
    />
  </SvgIcon>
)
export default GPlusIcon
