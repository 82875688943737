import React from 'react'
import { makeStyles } from '@material-ui/core'
import type { Theme } from '@material-ui/core/styles'
import type { StyleClasses } from '@ui/core/theme'
import { Grid, Typography } from '@ui/paintscout'

const useStyles = makeStyles<Theme, PageSectionProps>(
  (theme) => ({
    root: {},
    image: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center'
    },
    titleContainer: {
      paddingBottom: '0 !important',
      position: 'relative',
      margin: '20px',
      overflow: 'hidden',
      '&:after': {
        content: '""',
        display: 'block',
        width: '35%',
        height: '20px',
        backgroundColor: '#CB1D39',
        position: 'absolute',
        bottom: '0',
        left: '-70px',
        transform: 'skew(55deg)',
        [theme.breakpoints.down('sm')]: {
          display: 'none'
        }
      },
      borderBottom: 'solid 2px #CB1D39',
      [theme.breakpoints.down('sm')]: {
        padding: '8px !important'
      }
    },
    titleAccent: {
      height: '100%',
      width: '100%'
    },
    titleAccentSlant: {},
    title: {
      fontSize: '30px',
      fontWeight: 'bold',
      fontFamily: `'MacklinDisplay-CustomFont-BM', 'Times New Roman', serif`
    },
    description: {
      whiteSpace: 'break-spaces',
      fontFamily: `'Moderat-CustomFont-BM', Arial, sans-serif`
    }
  }),
  { name: 'PageSection' }
)

export interface PageSectionProps {
  classes?: StyleClasses<typeof useStyles>
  title: string
  description: React.ReactNode
  image: string
  imageAlt: string
}

function PageSection(props: PageSectionProps) {
  const classes = useStyles(props)

  const { title, description, image, imageAlt } = props

  return (
    <Grid container className={classes.root} direction="column" spacing={5}>
      <Grid item container className={classes.titleContainer}>
        <Grid item md={4} style={{ paddingBottom: 0 }}>
          <div className={classes.titleAccent}>
            <div className={classes.titleAccentSlant} />
          </div>
        </Grid>
        <Grid item md={8} style={{ paddingBottom: 0 }}>
          <Typography variant="h3" className={classes.title}>
            {title}
          </Typography>
        </Grid>
      </Grid>
      <Grid item container spacing={4}>
        <Grid item md={4} className={classes.image}>
          <img src={image} alt={imageAlt} style={{ maxWidth: '100%' }} />
        </Grid>
        <Grid item md={8}>
          <Typography variant="body1" className={classes.description} style={{ marginLeft: '10px' }}>
            {description}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default PageSection
