// Call task based on file type
export default function getRequiredTask(type: string) {
  let res: string
  switch (type) {
    case 'text/csv':
    case 'csv':
      res = 'handleCSVUpload'
      break
    case 'application/pdf':
      res = null
      break

    case 'video/mp4':
      res = null
      break

    case 'image/svg+xml':
    case 'image/svg':
    case 'svg':
      res = null
      break

    case 'jpg':
    case 'jpeg':
    case 'png':
    case 'gif':
      res = 'imageResize'
      break

    default:
      res = null
      break
  }
  return res
}
