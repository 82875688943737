import React from 'react'
// import { compose as reduxCompose } from 'redux'
import classnames from 'classnames'
import type { QuoteFile } from 'paintscout'

export function cloneElementWithClassName(child: React.ReactElement<any>, className: string) {
  return React.cloneElement(child, {
    className: classnames(child.props.className, className)
  })
}

export function cloneChildrenWithClassName(children: React.ReactNode, className: string) {
  return React.Children.map(children, (child) => {
    return React.isValidElement(child) && cloneElementWithClassName(child, className)
  })
}

/**
 * Finds the closest scrollable parent
 */
export function getScrollParent(node: any): HTMLElement {
  if (!node) {
    return null
  }

  if (node.scrollHeight > node.clientHeight) {
    return node
  } else {
    return getScrollParent(node.parentNode)
  }
}

export function generateSrcSet({ file, svg, size }: { file: QuoteFile; svg?: boolean; size?: number }): string {
  const regex = /(.*amazonaws\.com)/
  if (!file.src.match(regex)) {
    return null
  }
  const base = file.src.match(regex)[1]
  const fileIndex = file.s3PublicKey.lastIndexOf('/')
  const fileName = file.s3PublicKey.slice(fileIndex)
  const id = fileName.split('.')[0]
  const idIndex = file.s3PublicKey.indexOf(id)
  const dist = file.s3PublicKey.slice(0, idIndex)

  // Svg url
  if (svg) {
    return base + `/${dist}` + id + '/svg' + fileName
  }

  // Singular size url
  if (size) {
    return base + `/${dist}` + id + `/${size}` + fileName
  }

  // Keep in sync w/ apps/api/src/services/task/image-resize/image-resize.ts
  const resizeWidths = [50, 300, 500, 700, 900, 1200]
  // Resized srcSet
  const lines = resizeWidths.map((width) => {
    return base + `/${dist}` + `${id}` + `/${width}` + `${fileName}` + ` ${width}w`
  })
  return lines.join(', ')
}
