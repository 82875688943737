/**
 * @module builder
 */
import type { QuoteDocument, OptionsDocument, QuoteOptions } from 'paintscout'
import type { QuoteItemSection, QuoteItemConsumer, RenderableItem, RenderableSubItem } from '../../../index'
import { filterOrderItems, getItemOrder } from '../../get-items/util'
import { trimLineItem } from '../../../line-items'
import { trimSubstrate } from '../../../substrates'
import { getQuoteOptions, filterItem } from '../../../util'
import getSubstrateAreas from '../util/get-substrate-areas'
import { filterCrewSubstrateItems } from './util'

/**
 * Get Bid, Options, or Archived list of substrates for a WorkOrder.
 */
export function getCrewItemsBySubstrate(args: {
  quote: QuoteDocument
  options?: OptionsDocument
  overrideOptions?: { [key: string]: any }
  section: QuoteItemSection
  consumer: QuoteItemConsumer
  keys?: string[]
  showGroups?: boolean
  showLineItems?: boolean
  showValues?: boolean
}): RenderableItem[] {
  const { quote, options, overrideOptions, section, consumer, keys } = args
  const showLineItems = args.showLineItems === false ? false : true
  const showValues = args.showValues === false ? false : true

  const quoteOptions: QuoteOptions = getQuoteOptions({
    options,
    quote,
    override: overrideOptions
  })

  const substrateOrder = getItemOrder('substrate', section, quote)

  const substrates = substrateOrder
    .map((orderItem) => {
      if (filterOrderItems({ orderItem, showLineItems, keys, workOrder: true })) {
        return null
      }

      if (orderItem.type === 'substrate') {
        const substrate = quote.substrates[orderItem.key]

        if (
          !substrate ||
          (orderItem.parent &&
            ((quote.groups && quote.groups[orderItem.parent] && section !== quote.groups[orderItem.parent].section) ||
              !filterItem(substrate, section)))
        ) {
          return null
        }

        // Get areas that are associated with this substrate in renderable form
        const substrateAreas = getSubstrateAreas({
          quote,
          options: quoteOptions,
          clientOptions: options,
          substrateKey: orderItem.key,
          section,
          showValues,
          consumer,
          view: 'substrate',
          showGroup: true
        })

        const { subItemTotal, hours, prep, areaIds } = substrateAreas.reduce(
          (
            acc: { subItemTotal: number; hours: number; prep: number; areaIds: string[] },
            substrateArea: RenderableSubItem
          ) => {
            return {
              hours: acc.hours + Number(substrateArea?.additionalFields?.painting ?? 0),
              prep: acc.prep + Number(substrateArea?.additionalFields?.prep ?? 0),
              subItemTotal: acc.subItemTotal + Number(substrateArea.value),
              areaIds: [...acc.areaIds, substrateArea?.additionalFields?.areaKey ?? null].filter(Boolean)
            }
          },
          { subItemTotal: 0, hours: 0, prep: 0, areaIds: [] }
        )

        // Filter out un-needed substrate items for section
        if (filterCrewSubstrateItems({ substrate, substrateAreas, section })) {
          return null
        }

        // Trim substrate with its areaSubstrates
        const trimmedSubstrate = trimSubstrate({
          quote,
          clientOptions: options,
          item: { ...orderItem, ...substrate },
          areaIds,
          subItems: substrateAreas,
          options: quoteOptions,
          consumer,
          section,
          hours,
          prep,
          price: subItemTotal
        })

        return trimmedSubstrate
      } else if (orderItem.type === 'line_item') {
        const lineItem = quote.lineItems[orderItem.key]
        if (lineItem && filterItem(lineItem, section)) {
          return trimLineItem({ ...orderItem, ...lineItem }, quoteOptions, consumer, options) as RenderableItem
        }
      }

      return null
    })
    .filter((item) => item)

  return substrates
}
