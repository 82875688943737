import React, { useRef, forwardRef, useMemo, useImperativeHandle, useEffect } from 'react'
import type { Editor, Extensions, FocusPosition } from '@tiptap/react'
import { Extension } from '@tiptap/core'
import { Node } from '@tiptap/core'
import type { StyleClasses } from '../styles'
import StarterKit from '@tiptap/starter-kit'
import Color from '@tiptap/extension-color'
import Highlight from '@tiptap/extension-highlight'
import TextStyle from '@tiptap/extension-text-style'
import Link from '@tiptap/extension-link'
import TextAlign from '@tiptap/extension-text-align'
import Underline from '@tiptap/extension-underline'
import HardBreak from '@tiptap/extension-hard-break'
import CharacterCount from '@tiptap/extension-character-count'
import { useEditor, EditorContent } from '@tiptap/react'
import BulletList from '@tiptap/extension-bullet-list'
import type { Context } from '@paintscout/util/templater'

import lodashDebounce from 'lodash/debounce'

import InputLabel from '../InputLabel'

import { TipTapToolbar } from './TipTapToolbar'
import { useEditorStyles } from './useEditorStyles'
import { uuid } from '@paintscout/util/builder'
import Typography from '../Typography'
import striptags from 'striptags'

export type EditorMethods = {
  clearContent?: () => void
  getHTML?: () => string
  setHTML?: (value: string) => void
  isDirty?: () => boolean
}

export type EditorRef = React.MutableRefObject<(Editor & EditorMethods) | null>
export type ForwardedEditorRef = React.ForwardedRef<EditorMethods>

export interface ToolbarConfig {
  bold?: boolean
  italic?: boolean
  underline?: boolean
  color?: boolean
  highlight?: boolean
  headings?: boolean
  lists?: boolean
  align?: boolean
  template?: {
    tooltip?: string
    tags: Array<{
      key: string
      label: string
      hideValue?: boolean
    }>
  }
  link?: boolean
}

export interface EditorProps {
  classes?: StyleClasses<any>

  content: string
  disabled?: boolean
  required?: boolean

  bubbleMenuDisabled?: boolean
  floatingMenuDisabled?: boolean
  menuBarEnabled?: boolean

  label?: React.ReactNode
  sublabel?: React.ReactNode
  className?: string
  characterCount?: number

  fullWidth?: boolean
  singleLine?: boolean
  maxHeight?: number
  templateContext?: Context
  toolbar?: ToolbarConfig

  allowHtml?: boolean

  autofocus?: FocusPosition

  Toolbar?: (props: { editor: Editor }) => JSX.Element
  FloatingMenu?: (props: { editor: Editor }) => JSX.Element
  BubbleMenu?: (props: { editor: Editor }) => JSX.Element
  MenuBar?: (props: { editor: Editor }) => JSX.Element

  onChange?: (value: string) => void
  onReset?: () => void
  debounce?: boolean | number

  extensions?: Extensions
}

const TipTapEditor = forwardRef((props: EditorProps, ref: React.ForwardedRef<EditorMethods>) => {
  const {
    characterCount,
    extensions = [],
    content,
    label,
    sublabel,
    singleLine,
    disabled,
    debounce: debounceProp = true,
    onChange = () => null,
    required,
    ...otherProps
  } = props

  const debounceTimeout = typeof debounceProp === 'number' ? debounceProp : debounceProp ? 250 : null
  // Unique id to handle lookups w/ multiple editors on same page
  const editorId = useMemo(() => {
    return uuid().split('-')[0]
  }, [])

  const debouncedOnChange = useMemo(() => {
    return debounceTimeout ? lodashDebounce(onChange, debounceTimeout) : onChange
  }, [])

  const classes = useEditorStyles(props)
  const editorExtensions = useMemo(() => {
    return [
      Extension.create({
        name: 'shiftTab',
        addKeyboardShortcuts() {
          return {
            ['Shift-Tab']: () => {
              const { editor } = this

              const { from } = editor.state.selection
              const $from = editor.state.selection.$from

              const lineStartPos = from - $from.parentOffset

              const lineText = editor.state.doc.textBetween(lineStartPos, from, ' ')

              if (lineText.startsWith('\t')) {
                editor.commands.deleteRange({ from: lineStartPos, to: lineStartPos + 1 })
                return true
              }

              return true // prevent default behavior
            }
          }
        }
      }),
      Extension.create({
        name: 'literalTab',
        addKeyboardShortcuts() {
          return {
            Tab: () => {
              const { editor } = this
              if (editor.isActive('bulletList') || editor.isActive('orderedList')) {
                return editor.commands.sinkListItem('listItem')
              } else {
                const { from } = editor.state.selection
                const $from = editor.state.selection.$from

                const lineStartPos = from - $from.parentOffset

                editor.commands.insertContentAt({ from: lineStartPos, to: lineStartPos }, '\t')

                const newPos = from + 1
                editor.commands.setTextSelection(newPos)

                return true
              }
            }
          }
        }
      }),
      StarterKit.configure({
        hardBreak: false
      }),
      HardBreak.extend({
        addKeyboardShortcuts() {
          return {
            Enter: () => {
              return this.editor.commands.newlineInCode()
            },
            ['Shift-Enter']: () => {
              return this.editor.commands.enter()
            },
            ['Mod-Enter']: () => {
              return this.editor.commands.enter()
            }
          }
        }
      }),
      Underline,
      TextAlign.configure({
        alignments: ['left', 'center', 'right', 'justify'],
        types: ['heading', 'paragraph'],
        defaultAlignment: 'left'
      }),
      Link.configure({
        openOnClick: false
      }),
      CharacterCount.configure({
        limit: characterCount || null
      }),
      TextStyle,
      Color,
      Highlight.configure({
        multicolor: true
      }),
      BulletList,
      ...extensions
    ]
  }, [extensions]) as any as Extensions

  const SingleLiner = Node.create({
    name: 'singleLiner',
    topNode: true,
    content: 'block'
  })

  if (singleLine) {
    editorExtensions.unshift(SingleLiner)
  }

  const editor = useEditor({
    extensions: editorExtensions,
    content,
    editable: !disabled,
    editorProps: {
      handleScrollToSelection: (_view) => {
        return true
      },
      transformPastedHTML(html) {
        // Strip out header tags when pasting
        return striptags(html, [
          'b',
          'u',
          'i',
          'ins',
          'em',
          'br',
          'strong',
          'del',
          'p',
          'ul',
          'ol',
          'li',
          'img',
          'div',
          'span',
          'mark',
          'a'
        ])
      }
    },
    onUpdate: ({ editor }) => {
      debouncedOnChange?.(editor.getHTML())
    },
    parseOptions: {
      preserveWhitespace: true
    }
  })

  // Disabled sync
  useEffect(() => {
    if (editor) {
      editor.setOptions({
        editable: !disabled
      })
    }
  }, [disabled])

  const editorRef: EditorRef = useRef(null)
  useImperativeHandle(ref, () => ({
    clearContent: () => {
      editorRef.current?.commands?.clearContent()
    },
    getHTML: () => {
      return editorRef.current?.getHTML()
    },
    setHTML: (value: string) => {
      editorRef.current?.commands?.setContent(value)
    },
    isDirty: () => {
      return props.content !== editorRef.current?.getHTML()
    }
  }))
  const ToolbarComponent = useMemo(() => props.Toolbar ?? TipTapToolbar, [editorRef.current, props])
  if (!editor) return null
  editorRef.current = editor

  return (
    <>
      <div>
        {label ? (
          <InputLabel disabled={disabled} required={required} className={classes.label}>
            {label}
          </InputLabel>
        ) : null}
      </div>

      {sublabel ? (
        <div style={{ padding: '0px 0px 8px 0px' }}>
          <Typography variant="subtitle2">{sublabel}</Typography>
        </div>
      ) : null}

      <div className={classes.root} {...otherProps}>
        {ToolbarComponent && editor && <ToolbarComponent editor={editorRef.current} {...props} />}
        <EditorContent editor={editorRef.current} className={classes.editorContent} id={`EditorId-${editorId}`} />
      </div>
    </>
  )
})
TipTapEditor.displayName = 'TipTapEditor'

export { TipTapEditor as Editor }
export type { Editor as TipTapEditor }
