import { createFile } from '../builder'
import type { UploadOptions } from './s3UploadFile'
import s3UploadFile from './s3UploadFile'
import {
  getFileKey,
  // generateVideoThumbnail,
  getRequiredTask,
  getUploadUrl
  // getMp4ThumbnailKey,
  // getPageCount
} from './util'
import type { ApolloClient } from 'apollo-client'
import { gql } from 'apollo-boost'
import { cloudinaryUploadFile } from '../cloudinary'
import type { QuoteFile } from 'paintscout'
export interface UploadFileParams {
  file: File
  apolloClient: ApolloClient<any>
  uploadOptions: UploadOptions
}

export const RESIZE_IMAGE_FORMATS = ['jpeg', 'jpg', 'png', 'gif', 'svg']

export interface CsvOwner {
  value: string
  label: string
  email: string
  companyId?: string
}

export default async function uploadFile({ file, apolloClient, uploadOptions }: UploadFileParams): Promise<QuoteFile> {
  const { fileKey, initialImageWidth, fileType } = getFileKey(file, uploadOptions)
  const { s3UploadFeatureEnabled } = uploadOptions

  // If the file is pdf, or mp4 keep it uploaded through cloudinary
  if (['video/quicktime'].includes(file.type) || ['mp4', 'pdf'].includes(fileType) || !s3UploadFeatureEnabled) {
    const { secure_url, format, pages, public_id, width, height, type } = await cloudinaryUploadFile(
      file,
      uploadOptions
    )

    const quoteFile = createFile({ type: file.type })

    return {
      ...quoteFile,
      src: secure_url,
      cloudinaryPublicId: public_id,
      s3PublicKey: null,
      format,
      pages,
      width,
      height,
      type
    }
  }

  // Get a signed url to upload the file to
  const signedUploadUrl = await getUploadUrl({ apolloClient, fileKey })

  // Upload the file to s3
  const { bucket, ...uploadFileData } = await s3UploadFile(file, signedUploadUrl)

  // Todo: Handle PDF's as they come in to get pages
  // Currenlty just using cloudinary for pdfs
  // if (file.type.includes('pdf')) {
  //   const pages = await getPageCount(file)
  //   let base64pdf: any
  //   let binaryPdf: any
  //   const fileReader = new FileReader()
  //   // Onload of file read the file content
  //   fileReader.onload = (fileLoadedEvent) => {
  //     base64pdf = fileLoadedEvent.target.result
  //     console.log('base64pdf', base64pdf)
  //     console.log('base64pdf noStart', base64pdf.split(',')[1])
  //     console.log('base64pdf noStart', atob(base64pdf.split(',')[1]))
  //     binaryPdf = atob(base64pdf.split(',')[1])
  //     // const loadData = await pdfImgConvert.convert(binaryPdf, { height: 792, width: 612, page_numbers: [i] })
  //   }
  //   // Convert data to base64
  //   fileReader.readAsDataURL(file)
  //   for (let i = 1; i <= pages; i++) {
  //     console.log('pages', pages)
  //     console.log('i', i)
  //     // const loadData = await pdfjsLib.convert(binaryPdf, { height: 792, width: 612, page_numbers: [i] })
  //   }
  // }

  // Create thumbnail from mp4 and save
  // Mp4 through cloudinary atm
  // if (file.type.includes('mp4')) {
  //   const thumbFile = await generateVideoThumbnail(file)
  //   const thumbKey = getMp4ThumbnailKey(fileKey)
  //   const signedUploadThumbDetails = await getSignedUpload(apolloClient, thumbKey, 'jpeg')
  //   await s3UploadFile(thumbFile, {
  //     companyId,
  //     docId,
  //     signedUploadDetails: signedUploadThumbDetails
  //   })
  // }

  // Trigger the processing for files to get all sizes/views we need
  const requiredTask = getRequiredTask(fileType)
  if (requiredTask) {
    await apolloClient.query({
      query: gql`
        query processFile($task: String!, $fileParams: ProcessFileParams!, $uploadOptions: UploadOptions!) {
          processFile(task: $task, fileParams: $fileParams, uploadOptions: $uploadOptions)
        }
      `,
      variables: {
        task: requiredTask,
        fileParams: {
          srcUrl: uploadFileData.src,
          srcKey: uploadFileData.s3PublicKey,
          contentType: fileType,
          srcBucket: bucket,
          initialImageWidth: initialImageWidth
        },
        uploadOptions
      },
      fetchPolicy: 'network-only'
    })
  }

  // Turn into QuoteFile
  const quoteFile = createFile({ type: file.type })

  const res: QuoteFile = {
    ...uploadFileData,
    ...quoteFile,
    cloudinaryPublicId: null
  }

  return res
}
