import React from 'react'

import type { Theme } from '@material-ui/core'
import { makeStyles, useTheme, useMediaQuery } from '@material-ui/core'
import type { PresentationSectionProps } from '../..'
import { Grid, Typography } from '@ui/paintscout'
import { socialIcons } from './SocialsForm'

const useStyles = makeStyles((_theme: Theme) => {
  return {
    root: {},
    content: {},
    singleIcon: {
      display: 'flex',
      justifyContent: 'center'
    },
    icon: {
      fontSize: '60px',
      height: '60px',
      width: '60px',
      '&:hover': {
        cursor: 'pointer'
      }
    }
  }
})

export default function Social(props: PresentationSectionProps) {
  const { section, WrapperComponent } = props
  const classes = useStyles(props)
  const { fields, title, background = {} } = section
  const { alignment = 'space-evenly', padding = 1 } = background
  const { rows } = fields
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const responsiveSpacing = isMobile ? 3 : 4
  const iconSize = isMobile ? Math.min(Math.max(padding + 1, 2), 3) : padding
  function SocialsIcon({ row }) {
    if (!row?.icon) {
      return null
    }
    const { icon, prefix = '' } = row
    const SocialIcon = socialIcons[icon].icon

    return (
      <Grid item xs={iconSize} classes={{ root: classes.singleIcon }}>
        <SocialIcon
          className={classes.icon}
          onClick={() => {
            // open the link in a new window
            window.open(prefix + row.link, '_blank')
          }}
        />
      </Grid>
    )
  }

  return (
    <WrapperComponent {...props}>
      <Grid container spacing={3}>
        {title && (
          <Grid item md={12}>
            <Typography variant={'h2'}>{title}</Typography>
          </Grid>
        )}
        <Grid item md={12}>
          <Grid container justifyContent={alignment} spacing={responsiveSpacing}>
            {rows.map((row, index) => (
              <SocialsIcon key={index} row={row} />
            ))}
          </Grid>
        </Grid>
      </Grid>
    </WrapperComponent>
  )
}
