import React from 'react'
import { makeStyles } from '@material-ui/core'
import type { Theme } from '@material-ui/core/styles'
import type { StyleClasses } from '@ui/core/theme'
import { Typography } from '@ui/paintscout'
import { PageSection } from './_components'
import type { PresentationSectionProps } from '../../../..'

const useStyles = makeStyles<Theme, BenjaminMooreMainPageProps>(
  (theme) => ({
    root: {
      display: 'flex',
      gap: '30px'
    },
    pageTitle: {
      fontSize: '50px',
      marginTop: '70px',
      [theme.breakpoints.down('sm')]: {
        marginTop: '30px'
      }
    },
    pageAccent: {
      minWidth: '70px',
      minHeight: '100%',
      backgroundColor: '#061947',
      position: 'relative',
      [theme.breakpoints.down('sm')]: {
        display: 'none'
      },
      '&::before': {
        content: '""',
        position: 'absolute',
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        backgroundImage:
          "url('https://res.cloudinary.com/taptapquote/image/upload/v1723493490/paintscout/partners/benjamin-moore/ghmir7ruh0pvrzrchfh2.png')",
        backgroundRepeat: 'repeat',
        opacity: 0.32
      }
    },
    fullSizeLogo: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      paddingTop: '40px'
    },
    fullSizeLogoImg: {
      width: '100%',
      maxWidth: '200px'
    },
    footerText: {
      fontSize: '12px',
      textAlign: 'center',
      paddingBottom: '40px'
    }
  }),
  { name: 'BenjaminMooreMainPage' }
)

export interface BenjaminMooreMainPageProps {
  classes?: StyleClasses<typeof useStyles>
}

function BenjaminMooreMainPage(props: PresentationSectionProps) {
  const classes = useStyles(props)

  const { quote } = props

  return (
    <div className={classes.root}>
      <div className={classes.pageAccent} />
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '40px'
        }}
      >
        <Typography variant="h1" className={classes.pageTitle}>
          Why Benjamin Moore:
        </Typography>
        {quote?.presentationTemplate?.advanced?.partnerContent?.benjaminMoore?.pages?.whyBenjaminMoore
          ?.legacyofCraftsmanship && (
          <PageSection
            title="A Legacy of Crasftmanship"
            description={
              <>
                Benjamin Moore® has been dreaming in color since 1883. Innovation, craftsmanship, and leadership define
                Benjamin Moore, a company that has been committed to providing superior paint products, unrivaled
                colors, and best-in-class trade services for more than 140 years. <br />
                <br />
                Our paints contain the highest-quality ingredients, including proprietary pigments and resins that are
                meticulously handcrafted and designed to perform. We achieve this through our team of 100 chemists,
                engineers, and technicians at our state-of-the-art research and development facility.
              </>
            }
            image="https://res.cloudinary.com/taptapquote/image/upload/v1723492095/paintscout/partners/benjamin-moore/vdsqi0qw24vll2anbsuh.jpg"
            imageAlt="A Legacy of Crasftmanship"
          />
        )}
        {quote?.presentationTemplate?.advanced?.partnerContent?.benjaminMoore?.pages?.whyBenjaminMoore
          ?.unmatchableColor && (
          <PageSection
            title="Unmatchable Color"
            description={
              <>
                Our 3,500+ paint colors span everything from bold modern hues to timeless classics, offering endless
                possibilities for your projects. When you find the perfect color, nothing else will do. Perfection comes
                from our paint and our proprietary Gennex® colorants working together to create colors that are
                impossible to replicate. Insist on Benjamin Moore.
              </>
            }
            image="https://res.cloudinary.com/taptapquote/image/upload/v1723492095/paintscout/partners/benjamin-moore/jd3ws4w7kci1tzk0fpqe.jpg"
            imageAlt="Unmatchable Color"
          />
        )}
        {quote?.presentationTemplate?.advanced?.partnerContent?.benjaminMoore?.pages?.whyBenjaminMoore
          ?.localSupport && (
          <PageSection
            title="Local Support"
            description={
              <>
                Since our founding in 1883, Benjamin Moore has stood up for local businesses by selling exclusively
                through locally owned and operated paint, decorating, and hardware stores. These retailers not only
                offer paint and color expertise and personal service but also know and support your community.
              </>
            }
            image="https://res.cloudinary.com/taptapquote/image/upload/v1723492095/paintscout/partners/benjamin-moore/necibyz4mgxeijzlrg0q.jpg"
            imageAlt="Local Support"
          />
        )}
        <div className={classes.fullSizeLogo}>
          <img
            className={classes.fullSizeLogoImg}
            src="https://res.cloudinary.com/taptapquote/image/upload/v1723492095/paintscout/partners/benjamin-moore/mk26y4forjrjysrutlqz"
            alt="Benjamin Moore fullsize logo"
          />
        </div>
        <div>
          <p className={classes.footerText}>
            ©2024 Benjamin Moore & Co. Benjamin Moore, Color Preview, and the triangle “M” symbol are registered
            trademarks licensed to Benjamin Moore & Co. 7/24
          </p>
        </div>
      </div>
    </div>
  )
}

export default BenjaminMooreMainPage
