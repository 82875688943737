import React from 'react'
import type { Theme } from '@material-ui/core/styles'
import { makeStyles } from '@material-ui/core/styles'

import { Button, useClientOptions, Typography, Grid } from '@ui/paintscout'
import { useQuote } from '../../../context'
import type { PartnerContentFeatures, PresentationOption, PresentationPage, PresentationSection } from 'paintscout'

import {
  getQuotePresentation,
  filterActivePresentationPages,
  filterActivePresentationSections,
  getFeature
} from '@paintscout/util/builder'

import { usePresentationNav } from '../PresentationNavContext'

import TocIcon from '@material-ui/icons/Toc'

import find from 'lodash/find'

import classnames from 'classnames'
import SherwinWilliamsNav from '../partners/SherwinWilliamsNav'
import BenjaminMooreNav from '../partners/BenjaminMooreNav/BenjaminMooreNav'

const useStyles = makeStyles((theme: Theme) => {
  return {
    textRoot: {
      '&::-moz-box-shadow': {
        // boxShadow: 'inset  0 -2px 3px -2px grey'
      },
      '&::-webkit-box-shadow': {
        // webkitBoxShadow: 'inset  0 -2px 3px -2px grey'
      },
      // boxShadow: 'inset  0 -2px 3px -2px grey',
      '&::-webkit-scrollbar': {
        width: '0.4em'
      },
      '&::-webkit-scrollbar-track': {
        // boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
        // webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: 'rgba(0,0,0,.1)',
        outline: 'none'
      },
      display: 'flex',
      flexDirection: 'column',
      maxHeight: '72vh',
      overflowY: 'auto',
      overflowX: 'hidden',
      '& button': {
        marginTop: theme.spacing(1)
      }
    },
    buttonWrapper: {
      marginBottom: theme.spacing(1),
      '&:first-child button': {
        marginTop: 0
      },

      '&:last-child': {
        marginBottom: 0
      }
    },
    title: {
      '& h3': {
        display: 'flex',
        alignItems: 'center',
        fontSize: '1.1em',
        marginBottom: theme.spacing(1),

        paddingBottom: theme.spacing(1),
        // borderBottom: `1px solid ${theme.palette.grey[500]}`
        '& svg': {
          fontSize: '1em',
          marginRight: theme.spacing(0.5)
        }
      }
    },
    button: {
      whiteSpace: 'normal',
      textAlign: 'left',
      justifyContent: 'flex-start',
      height: 'auto',
      marginLeft: '10px',

      padding: `${theme.typography.pxToRem(5)} 0`,
      paddingTop: 0,
      borderRadius: 0,
      borderBottom: `2px solid transparent`,
      '&:hover': {
        color: theme.palette.primary.main,
        background: 'transparent'
      }
    },
    currentPage: {
      color: theme.palette.primary.main,
      borderBottomColor: theme.palette.primary.main
    },
    currentSection: {
      color: theme.palette.primary.main,
      borderBottomColor: theme.palette.primary.main
    },
    highlight: {
      // color: theme.palette.common.black
    },
    textDiv: {
      overflow: 'hidden'
      // width: '170px'
    }
  }
})

export interface PresentationNavTextProps {}

const PresentationNavText = ({ ...props }: PresentationNavTextProps) => {
  const classes = useStyles(props)
  const { options } = useClientOptions()
  const { quote } = useQuote()
  const { onPageClick, onSectionClick, isSinglePage, currentPage, currentSection, view } = usePresentationNav()
  const presentation = getQuotePresentation({ quote, options, view })
  const partnerContent = getFeature({ options: options, path: 'partnerContent' })

  let activeItems = []
  let highlight = null
  if (isSinglePage) {
    activeItems = presentation.pages[0].sections.filter(filterActivePresentationSections)
    highlight = activeItems.reduce((acc: string) => {
      const estimateSection = find(activeItems, { type: 'quote' })
      return acc || (estimateSection ? estimateSection.key : null)
    }, null)
  } else {
    activeItems = presentation.pages.filter(filterActivePresentationPages)
    highlight = activeItems.reduce((acc: string, page: PresentationPage) => {
      const estimateSection = find(page.sections, { type: 'quote' })
      return acc || (estimateSection ? page.key : null)
    }, null)
  }

  return (
    <div className={classes.textRoot}>
      <div className={classes.title}>
        <Typography variant={'h3'}>
          <TocIcon />
          Table of Contents
        </Typography>
      </div>
      {activeItems.map((item: PresentationSection, index) => {
        return (
          <div className={classes.buttonWrapper} key={item.key}>
            <Button
              disableRipple={true}
              classes={{
                root: classnames({
                  [classes.button]: true,
                  [classes.currentPage]: isCurrentItem(item, index, currentPage),
                  [classes.currentSection]: isSinglePage && isCurrentItem(item, index, currentSection),
                  [classes.highlight]: highlight === item.key
                })
              }}
              variant={'text'}
              onClick={(ev) => {
                if (isSinglePage && onSectionClick) {
                  onSectionClick(ev, item.key)
                } else if (onPageClick) {
                  onPageClick(ev, item.key)
                }
              }}
            >
              <div className={classes.textDiv}>
                <Typography noWrap style={{ whiteSpace: 'normal' }} variant={'h5'}>
                  {ellipsize(item.title)}
                </Typography>
              </div>
            </Button>
          </div>
        )
      })}
      {getPartnerPresentation(partnerContent, presentation)}
    </div>
  )

  function ellipsize(input: string) {
    if (!input) {
      return ''
    }
    const words = input.split(' ')
    words.forEach((word, index) => {
      if (word.length < 15) {
        words[index] = word
      } else {
        words[index] = `${word.slice(0, 12)}...`
      }
    })
    return words.join(' ')
  }

  function isCurrentItem(item: PresentationSection, index: number, currentKey: string) {
    return (currentKey === null && index === 0) || item.key === currentKey
  }

  function getPartnerPresentation(partnerContent: PartnerContentFeatures, presentation: PresentationOption) {
    const partnerPresentationsNav = []

    if (!partnerContent?.enabled) return []

    if (
      partnerContent?.sherwinWilliams?.presentationPages?.enabled &&
      presentation?.advanced?.partnerContent?.sherwinWilliams?.enabled
    )
      partnerPresentationsNav.push(<SherwinWilliamsNav presentation={presentation} />)
    // Add more partner content here...

    if (
      partnerContent?.benjaminMoore?.presentationPages?.enabled &&
      presentation?.advanced?.partnerContent?.benjaminMoore?.enabled
    )
      partnerPresentationsNav.push(<BenjaminMooreNav presentation={presentation} />)

    return (
      <Grid container spacing={0} direction="column" md>
        {partnerPresentationsNav.map((partnerPresentationItem, index) => (
          <Grid item md style={{ marginTop: '6px' }} key={index}>
            {partnerPresentationItem}
          </Grid>
        ))}
      </Grid>
    )
  }
}

export default PresentationNavText
