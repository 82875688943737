import { Box, makeStyles } from '@material-ui/core'
import type { Theme } from '@material-ui/core/styles'
import { Grid, Typography } from '@ui/paintscout'
import React from 'react'
import SWLink from '../SWLink'

const useStyles = makeStyles<Theme, ContentBlockProps>(
  (theme) => ({
    root: {
      padding: '50px',
      background: '#fff'
    },
    content: {
      [theme.breakpoints.down('sm')]: {
        alignItems: 'center'
      }
    },
    preTitle: {
      marginBottom: '10px',
      fontFamily: 'CustomFont-SW',
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: '16px',
      letterSpacing: '1.5px',
      textTransform: 'uppercase'
    },
    title: {
      fontFamily: 'CustomFont-SW',
      fontSize: '44px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '48px',
      letterSpacing: '-1.5px',
      [theme.breakpoints.down('sm')]: {
        textAlign: 'center'
      }
    },
    description: {
      marginTop: '10px',
      fontFamily: 'CustomFont-SW',
      fontSize: '20px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '24px',
      letterSpacing: '-0.5px',

      [theme.breakpoints.down('sm')]: {
        textAlign: 'center'
      }
    },
    image: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center'
    }
  }),
  { name: 'ContentBlock' }
)

export interface ContentBlockProps {
  img: string
  preTitle?: React.ReactNode
  title: React.ReactNode
  description: React.ReactNode
  linkHref?: string
  linkLabel?: string
  backgroundColor?: string
  imgFirst?: boolean
  styles?: React.CSSProperties
}

function ContentBlock(props: ContentBlockProps) {
  const classes = useStyles(props)

  const { img, preTitle, title, description, linkHref, linkLabel, backgroundColor, imgFirst, styles } = props

  return (
    <Box className={classes.root} style={{ background: backgroundColor, ...styles }}>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        direction={imgFirst ? 'row' : 'row-reverse'}
        spacing={2}
      >
        <Grid item md={6} xl={6} className={classes.image}>
          <img src={img} style={{ maxWidth: '100%' }} />
        </Grid>
        <Grid item md={4} xl={6} container direction="column" className={classes.content}>
          {preTitle && (
            <Grid item>
              <Typography variant="h4" className={classes.preTitle} style={{ marginTop: '20px' }}>
                {preTitle}
              </Typography>
            </Grid>
          )}
          <Grid item>
            <Typography variant="h1" className={classes.title}>
              {title}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1" className={classes.description}>
              {description}
            </Typography>
          </Grid>
          {!!linkHref && !!linkLabel && (
            <Grid item>
              <SWLink style={{ marginTop: '20px' }} href={linkHref} label={linkLabel} theme="dark" />
            </Grid>
          )}
        </Grid>
      </Grid>
    </Box>
  )
}

export default ContentBlock
