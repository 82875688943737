import React, { useContext, useMemo } from 'react'
import type { WithStyles, Theme } from '@material-ui/core/styles'
import { withStyles, createStyles } from '@material-ui/core/styles'
import { useClientOptions, useUser, Collapse, Hidden } from '@ui/paintscout'
import type { QuoteItemView, RenderableItem } from '@paintscout/util/builder'
import { getItems, getQuoteOptions, getFeatures, getObjectLabels } from '@paintscout/util/builder'
import AddBoxIcon from '@material-ui/icons/AddBox'
import ArchiveIcon from '@material-ui/icons/Archive'
import ListIcon from '@material-ui/icons/List'
import ConvertIcon from '@material-ui/icons/SwapHoriz'

import get from 'lodash/get'

import { Typography } from '@ui/paintscout'

import type { ItemTableProps } from '../../../../ItemTable'
import ItemTable, { ItemTableCell, ItemColumn } from '../../../../ItemTable'
import { QuoteContext } from '../../../../context/QuoteContext'
import ActionColumn from '../../../../ItemTable/columns/ActionColumn'

import { Grid } from '@ui/paintscout'

import AdditionalWorkSignature from './AdditionalWorkSignature'

const styles = (theme: Theme) => {
  return createStyles({
    root: {
      marginTop: theme.spacing(2)
    },
    tableHead: {
      background: theme.palette.grey[200]
    },
    headerCell: {
      color: theme.palette.grey[800]
    },
    heading: {
      marginBottom: theme.spacing(1.5)
    }
  })
}

export interface AdditionalTableProps extends WithStyles<typeof styles> {
  selectedItems?: ItemTableProps['selectedItems']
  onItemSelection?: ItemTableProps['onItemSelection']
  onItemAction?: ItemTableProps['onItemAction']
  collapsed?: boolean
}

function AdditionalTable({ classes, collapsed, onItemAction, ...itemTableProps }: AdditionalTableProps) {
  const { quote, tableView, isEditable, onReorder, onAddItem } = useContext(QuoteContext)
  const { options } = useClientOptions()
  const { isAdmin } = useUser()
  const features = getFeatures({ options })
  const canConvertToLineItem = get(features, 'quotes.convertToLineItem')

  const objectLabels = getObjectLabels({ options, invoice: quote.is_invoice })

  const { addIndex, showPrices, hideAllPrices } = getQuoteOptions({ quote, options })
  const approvedView = (quote.additionalWork?.approvedView as QuoteItemView) ?? tableView
  const items: RenderableItem[] = useMemo(() => {
    const items = getItems({
      quote,
      section: 'additional',
      view: approvedView,
      consumer: 'customer',
      options,
      overrideOptions: { showPrices: true }
    }).map((item) => {
      const itemDescription = item.description.useCustom ? item.description.custom : item.description.default
      if (!itemDescription && !item.value && item.subItems && item.subItems.length === 1) {
        const firstSubItem = item.subItems.shift()
        return {
          ...item,
          description: { useCustom: false, default: firstSubItem.name, custom: '' },
          value: firstSubItem.value
        }
      }

      return item
    })

    return items
  }, [quote, tableView])

  if (items.length === 0) {
    return null
  }

  if (collapsed && !isEditable) {
    return null
  }

  function handleReorder({ items, addIndex }) {
    onReorder?.({ items, section: 'additional', view: approvedView, addIndex })
  }

  return (
    <div className={classes.root}>
      <Grid container spacing={1} alignItems={'center'} className={classes.heading}>
        <Grid item>
          <Typography variant={'h3'}>{objectLabels.additional.plural}</Typography>
        </Grid>
        <Grid item>
          <AdditionalWorkSignature />
        </Grid>
      </Grid>

      <Collapse show={!collapsed}>
        <ItemTable
          classes={{
            tableHead: classes.tableHead
          }}
          items={items}
          addButtonLabel={'Add Area or Line Item'}
          checkboxes={isEditable}
          showAddButton={false}
          addIndex={addIndex.additional}
          onReorder={handleReorder}
          onItemClick={
            !quote.is_invoice || isAdmin
              ? (ev, item) => onItemAction('edit', 'additional', item as RenderableItem)
              : null
          }
          onAddClick={(ev, insertAfterKey, type) => onAddItem('additional', insertAfterKey, type)}
          renderHeadColumns={() => (
            <>
              <ItemTableCell className={classes.headerCell}>Item</ItemTableCell>
              <ItemTableCell className={classes.headerCell} align="right">
                {!hideAllPrices && (
                  <Hidden smDown implementation="css">
                    {objectLabels.currency.symbol}
                  </Hidden>
                )}
              </ItemTableCell>

              {/* action cell */}
              {isEditable && <ItemTableCell className={classes.headerCell} />}
            </>
          )}
          renderRowColumns={(item: RenderableItem) => {
            const actions = []
            {
              /*if (isAdmin) {
              actions.push({
                key: 'edit',
                label: 'Edit',
                icon: EditIcon
              })
            }*/
            }

            if (!quote.is_invoice) {
              actions.push({
                key: 'move-to-bid',
                label: `Move to ${objectLabels.quote.value}`,
                icon: AddBoxIcon
              })
            }

            actions.push({
              key: 'make-option',
              label: 'Make Option',
              icon: AddBoxIcon
            })

            actions.push({
              key: 'archive',
              label: 'Archive',
              icon: ArchiveIcon
            })

            if (item.type === 'group') {
              actions.push({
                key: 'ungroup',
                label: 'Ungroup',
                icon: ListIcon
              })
            }

            if (canConvertToLineItem && item.type !== 'line_item') {
              actions.push({
                key: 'convert',
                label: 'Convert to Line Item',
                icon: ConvertIcon
              })
            }

            return (
              <>
                <ItemTableCell colSpan={2}>
                  <ItemColumn
                    item={item}
                    isEditable={isEditable}
                    showAmount={showPrices && !hideAllPrices}
                    consumer="customer"
                    section={'additional'}
                  />
                </ItemTableCell>
                {isEditable && (
                  <ItemTableCell cancelDrag isControl>
                    <ActionColumn
                      onActionClick={(ev, action) => onItemAction(action, 'additional', item)}
                      actions={actions}
                    />
                  </ItemTableCell>
                )}
              </>
            )
          }}
          {...itemTableProps}
        />
      </Collapse>
    </div>
  )
}

export default withStyles(styles)(AdditionalTable)
