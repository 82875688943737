import React from 'react'
import type { SvgIconProps } from '@material-ui/core/SvgIcon'
import SvgIcon from '@material-ui/core/SvgIcon'

const XIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox={'0 0 250 250'} {...props}>
    <path d="M142.244 111.23L207.764 35.0774H192.238L135.347 101.199L89.9081 35.0774H37.5L106.212 135.065L37.5 214.923H53.027L113.105 145.096L161.092 214.923H213.5L142.24 111.23H142.244ZM120.978 135.946L114.016 125.99L58.6216 46.7645H82.4702L127.174 110.702L134.136 120.658L192.245 203.767H168.396L120.978 135.95V135.946Z" />
  </SvgIcon>
)
export default XIcon
